import { Box, Button, Container, Header, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import { useCallback } from "react";
import AudiencePopup from "./audiencepopup";
import './trainingaudience.css';
import AudienceCard from "./audiencecard";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { addAudience, audienceAttributesAsync, resetCurrentAudience, selectAllOptionValues, selectAudienceId, selectAudienceList, selectAudienceType, selectAudienceVisible, selectReportsType, setAudienceVisible } from "../../../../../features/audience";

const trainingAudienceDescription = "Add audience by selecting the users or by selecting the set of Cohort. You can add multiple set of users or Cohorts (optional)";

function TrainingAudienceForm() {
    const visible = useAppSelector(selectAudienceVisible);
    const selectedValues = useAppSelector(selectAllOptionValues);
    const audienceType = useAppSelector(selectAudienceType);
    const audienceId = useAppSelector(selectAudienceId);
    const reportsType = useAppSelector(selectReportsType);
    const audienceList = useAppSelector(selectAudienceList);
    const dispatch = useAppDispatch();
    const handleAudienceSave = useCallback(() => {
        const reportObject: any = {};
        reportObject[reportsType] = selectedValues.reports.map(formInfo => formInfo.value);

        const audienceObject = {
            audience_type: audienceType || 'Operations',
            audience_id: audienceId,
            orgs: selectedValues.org.map(formInfo => formInfo.value),
            suborg: selectedValues.suborgs.map(formInfo => formInfo.value),
            regions: selectedValues.regions.map(formInfo => formInfo.value),
            countries: selectedValues.countries.map(formInfo => formInfo.value),
            site_types: selectedValues.site_types.map(formInfo => formInfo.value),
            sites: selectedValues.sites.map(formInfo => formInfo.value),
            process_paths: selectedValues.process_paths.map(formInfo => JSON.parse(formInfo.value)),
            job_titles: selectedValues.job_titles.map(formInfo => JSON.parse(formInfo.value)),
            job_levels: selectedValues.job_level.map(formInfo => formInfo.value),
            amazon_tenure: selectedValues.amazon_tenure.map(formInfo => JSON.parse(formInfo.value)),
            employment_status: selectedValues.employment_status.map(formInfo => JSON.parse(formInfo.value)),
            reports: reportObject
        }
        dispatch(addAudience(audienceObject));
        dispatch(setAudienceVisible(false));
    }, [selectedValues, reportsType, audienceType, dispatch, audienceId]);

    const handleSelectUsers = useCallback(() => {
        dispatch(resetCurrentAudience());
        dispatch(audienceAttributesAsync("Operations"));
        dispatch(setAudienceVisible(true));
    }, [dispatch]);

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description={trainingAudienceDescription}
                >
                    Choose Audience
                </Header>
            }
            className="configuration-container"
        >
            <Button onClick={handleSelectUsers}>Select Users</Button>
            <Modal
                onDismiss={() => dispatch(setAudienceVisible(false))}
                visible={visible}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => dispatch(setAudienceVisible(false))}>Cancel</Button>
                            <Button variant="primary" onClick={handleAudienceSave}>Save Audience</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Audience Selection"
            >
                <AudiencePopup></AudiencePopup>
            </Modal>
            <hr className="audience-separator" />
            <SpaceBetween size="s">
                {
                    (audienceList || []).map((audience, index) => (
                        <AudienceCard key={index} audience={audience} trainingId={""} currentIndex={index} />
                    ))
                }
            </SpaceBetween>
        </Container>
    )
}

export default TrainingAudienceForm;
