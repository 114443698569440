import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { loadTrainingDetailsMetadata } from "./trainingDetailsAPI";

export interface TrainingMetadata {
    training_category: string[];
    ms_level: string[];
    training_program: string[];
    training_type: string[];
    training_modality: string[];
    training_module: string[];
};

export interface TrainingDetailFormOption {
    label: string;
    value: string;
};

export interface TrainingMetadataSelected {
    training_category: TrainingDetailFormOption | null;
    ms_level: TrainingDetailFormOption | null;
    training_program: TrainingDetailFormOption | null;
    training_type: TrainingDetailFormOption | null;
    training_modality: TrainingDetailFormOption | null;
    training_module: TrainingDetailFormOption | null;
    regulatory_requirement: TrainingDetailFormOption | null;
};

export interface TrainingDetailPayload {
    id: string;
    value: TrainingDetailFormOption;
}

export interface TrainingDetailState {
    options_metadata: TrainingMetadata;
    selected_values: TrainingMetadataSelected;
    status: 'idle' | 'loading' | 'failed';
};

const initialState: TrainingDetailState = {
    options_metadata: {
        training_category: [],
        ms_level: [],
        training_program: [],
        training_type: [],
        training_modality: [],
        training_module: []
    },
    selected_values: {
        training_category: null,
        ms_level: null,
        training_program: null,
        training_type: null,
        training_modality: null,
        training_module: null,
        regulatory_requirement: null
    },
    status: 'idle'
};

export const selectTrainingDetailOptions = (state: RootState, id: string) => state.trainingDetails.options_metadata[id as keyof TrainingMetadata];
export const selectTrainingDetailStatus = (state: RootState) => state.trainingDetails.status;
export const selectCurrentTrainingDetailOption = (state: RootState, id: string) => {
    if (state.trainingDetails.selected_values === undefined) {
        return null
    } else {
        return state.trainingDetails.selected_values[id as keyof TrainingMetadataSelected]
    }
};
export const selectedTrainingDetail = (state: RootState) => state.trainingDetails.selected_values;

export const loadTrainingDetailsMetadataAsync = createAsyncThunk(
    'trainingDetails/loadTrainingDetailsMetadataAsync',
    async () => {
        const response = await loadTrainingDetailsMetadata();

        return response;
    }
);

export const trainingDetailsSlice = createSlice({
    name: 'trainingDetails',
    initialState,
    reducers: {
        resetTrainingDetail: () => initialState,
        setTrainingDetailOption: (state, action: PayloadAction<TrainingDetailPayload>) => {
            state.selected_values[action.payload.id as keyof TrainingMetadataSelected] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadTrainingDetailsMetadataAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadTrainingDetailsMetadataAsync.fulfilled, (state, action: PayloadAction<TrainingMetadata>) => {
                state.status = 'idle';
                state.options_metadata = action.payload;
            })
            .addCase(loadTrainingDetailsMetadataAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setTrainingDetailOption, resetTrainingDetail } = trainingDetailsSlice.actions;

export default trainingDetailsSlice.reducer;