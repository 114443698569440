import { Container, Header, SpaceBetween, TextFilter, Spinner, Box, Button, FormField } from "@amzn/awsui-components-react";
import "./fetchtraining.css";
import { TrainingDetail, filterTrainingAsync, selectFetchTrainingList, selectFetchTrainingStatus, selectFetchTrainingText, setFetchTrainingStatus, setFilterText } from "../../../../../features/fetchTraining";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { fetchTrainingDetailsAsync, selectConfigurationStatus, selectLMSTraining, selectValidationStatus } from "../../../../../features/trainingConfiguration";
import { useCallback } from "react";
import FetchTrainingCard from "./fetchtrainingcard";
import { validateTraining } from "../../../../../features/fetchTraining/fetchTrainingAPI";
import { sendNotification } from "../../../../../features/notification";
import { useNavigate } from "react-router-dom";

const fetchTrainingDescription = "Search Knet for training to add to the Minerva library."

function FetchTrainingForm() {
    //TO-DO: Reset all information each time fetch training form loads. Behavior must be checked

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const filteringText = useAppSelector(selectFetchTrainingText);
    const trainingList = useAppSelector(selectFetchTrainingList);
    const fetchTrainingStatus = useAppSelector(selectFetchTrainingStatus);
    const trainingConfigurationStatus = useAppSelector(selectConfigurationStatus);
    const selectedLMSTraining = useAppSelector(selectLMSTraining);
    const validationStatus = useAppSelector(selectValidationStatus);

    const chooseTrainingHandler = useCallback((training: TrainingDetail) => {
        dispatch(setFetchTrainingStatus('loading'));
        validateTraining(training.id)
            .then(async (data) => {
                if (data.duplicate) {
                    dispatch(sendNotification(
                        {
                            type: 'error',
                            content: 'This training already exists in Minerva Library',
                            statusIconAriaLabel: 'error',
                            dismissLabel: 'Dismiss message',
                            dismissible: true
                        }
                    ))
                } else {
                    await dispatch(setFilterText(""));
                    await dispatch(fetchTrainingDetailsAsync(training));
                    navigate('/trainingconfiguration/verifytraining');
                }
                dispatch(setFetchTrainingStatus('idle'));
            })
            .catch((error) => {
                console.log(error, "Unable to validate training");
                dispatch(setFetchTrainingStatus('idle'));
            })
    }, [dispatch, navigate]);

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description={fetchTrainingDescription}
                >
                    Find Training
                </Header>
            }
            className="configuration-container"
        >
            <SpaceBetween direction="vertical" size="l">
                <div className="training-filter-wrapper">
                    <FormField
                        description="* Required"
                        errorText={selectedLMSTraining.id !== 'default-init-code' || !validationStatus ? "" : "You cannot proceed without selecting a training"}
                    >
                        <TextFilter
                            filteringText={filteringText}
                            filteringPlaceholder="Find Training"
                            filteringAriaLabel="Filter Trainings"
                            onChange={({ detail }) =>
                                dispatch(setFilterText(detail.filteringText))
                            }
                            onDelayedChange={({ detail }) => dispatch(filterTrainingAsync(detail.filteringText))}
                            disabled={selectedLMSTraining.id !== 'default-init-code'}
                        />
                    </FormField>
                </div>
            </SpaceBetween>
            {
                (fetchTrainingStatus === 'loading' || trainingConfigurationStatus === 'loading') ?
                    (
                        <div className="loading-bar">
                            Loading <Spinner />
                        </div>
                    ) :
                    (
                        <SpaceBetween direction="vertical" size="xs">
                            {/* TODO: Verify the list of trainings returned in each query. Implement infinite scroll if list is long */}
                            {trainingList.length > 0 ?
                                trainingList.map((training, index) => (
                                    <div key={index}>
                                        <div className="training-search-card">
                                            <FetchTrainingCard training={training} />
                                            <div className="training-search-card-button">
                                                <Button onClick={() => chooseTrainingHandler(training)}>Choose</Button>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )) :
                                (
                                    <p>No Trainings match currently</p>
                                )
                            }
                        </SpaceBetween>
                    )
            }
            {
                selectedLMSTraining.id !== 'default-init-code' ?
                    (
                        <div className="lms-selected-training">
                            <Box variant="h3" fontWeight="bold">Selected Training</Box>
                            <Box color="text-body-secondary">Verify selected Training Details in next step</Box>
                            <hr />
                            <FetchTrainingCard training={selectedLMSTraining} />
                        </div>
                    ) :
                    <></>
            }

        </Container>
    )
}

export default FetchTrainingForm;