import { Badge, Box, Button, Modal, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import './audiencecard.css';
import { AudienceDetail, audienceAttributesAsync, deleteAudience, editAudience, setAudienceVisible } from '../../../../../../features/audience';
import { useCallback, useEffect, useState } from 'react';
import { fetchAudienceCount, fetchAudienceCsvURL } from '../../../../../../features/audience/audienceAPI';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { selectAuthAdmin } from '../../../../../../features/auth';

interface AudienceCardProps {
    audience: AudienceDetail;
    trainingId: string;
    currentIndex: number;
    updateTimestamp?: string;
}

const fieldMap: any = {
    orgs: 'default',
    suborg: 'default',
    regions: 'default',
    countries: 'default',
    site_types: 'default',
    sites: 'default',
    job_levels: 'default',
    job_titles: 'profile_name',
    process_paths: 'value',
    amazon_tenure: 'value',
    employment_status: 'value',
    reports: 'reports'
}

const labelMap: any = {
    orgs: 'Orgs',
    suborg: 'Sub Orgs',
    regions: 'Regions',
    countries: 'Countries',
    site_types: 'Site Types',
    sites: 'Sites',
    job_levels: 'Job Levels',
    job_titles: 'Job Titles',
    process_paths: 'Process Paths',
    amazon_tenure: 'Amazon Tenure',
    employment_status: 'Emp Status',
    reports: 'Reports'
}

function AudienceCard(props: AudienceCardProps) {

    const [audienceCount, setAudienceCount] = useState("NA");
    const [audienceLoadingStatus, setAudienceLoadingStatus] = useState("idle");
    const location = useLocation();
    const editingEnabled = location.pathname.split("/").pop() === "trainingaudience";
    const adminCheck = useAppSelector(selectAuthAdmin);
    const dispatch = useAppDispatch();

    useEffect(() => {
        let dateDiffCheck = true;
        if (props.updateTimestamp) {
            const currentDate = (Date.parse(new Date().toISOString().replace("Z", '')));
            const updateDate = (Date.parse(props.updateTimestamp));
            const timeDiff = (currentDate - updateDate) / 60000;
            if (Math.floor(timeDiff) < 15) {
                dateDiffCheck = false;
            }
        }
        if (props.audience.audience_id && !editingEnabled && dateDiffCheck) {
            setAudienceLoadingStatus("loading");
            fetchAudienceCount(props.audience.audience_id)
                .then((data) => {
                    setAudienceCount(data.userCount);
                    setAudienceLoadingStatus("idle");
                })
                .catch((error) => {
                    console.log("Error: Unable to fetch audience count : ", props.audience.audience_id);
                    setAudienceCount("<span style='color:red'>(Unable to load: Please report issue)</span>")
                    setAudienceLoadingStatus("failed");
                })
        }

    }, [props, editingEnabled]);

    const handleDownloadCsv = useCallback((e: any) => {
        e.preventDefault();
        if (props.audience.audience_id) {
            setAudienceLoadingStatus("loading");
            fetchAudienceCsvURL(props.audience.audience_id, props.trainingId)
                .then((data) => {
                    setAudienceLoadingStatus("idle");
                    window.open(data.url, "_blank");
                })
                .catch((error) => {
                    setAudienceLoadingStatus("failed");
                    console.log("Unable to fetch CSV URL", props.audience.audience_id, error);
                })
        }
    }, [props.audience, props.trainingId]);

    const fieldHandler = (fieldKey: string, mainIndex: number) => {
        if (fieldMap[fieldKey] === 'default' && (props.audience[fieldKey as keyof AudienceDetail] as Array<string> || []).length > 0) {
            return (
                <div className="audience-badge-area" key={mainIndex}>
                    <span className="audience-badge-label">
                        <Box fontSize="body-s" color="text-body-secondary">{labelMap[fieldKey]}</Box>
                    </span>
                    <span>
                        {
                            (props.audience[fieldKey as keyof AudienceDetail] as Array<any>).map((value: string, index) => (
                                <span className="badge-spacer" key={index}>
                                    <Badge color="blue">{value}</Badge>
                                </span>
                            ))
                        }
                    </span>
                </div>
            )
        } else if (fieldMap[fieldKey] === 'value' && (props.audience[fieldKey as keyof AudienceDetail] as Array<any> || []).length > 0) {
            return (
                <div className="audience-badge-area" key={mainIndex}>
                    <span className="audience-badge-label">
                        <Box fontSize="body-s" color="text-body-secondary">{labelMap[fieldKey]}</Box>
                    </span>
                    <span>
                        {
                            (props.audience[fieldKey as keyof AudienceDetail] as Array<any>).map((fieldDetails: any, index) => (
                                <span className="badge-spacer" key={index}>
                                    <Badge color="blue">{fieldDetails.value}</Badge>
                                </span>
                            ))
                        }
                    </span>
                </div>
            )
        } else if (fieldMap[fieldKey] === 'profile_name' && (props.audience[fieldKey as keyof AudienceDetail] as Array<any> || []).length > 0) {
            return (
                <div className="audience-badge-area" key={mainIndex}>
                    <span className="audience-badge-label">
                        <Box fontSize="body-s" color="text-body-secondary">{labelMap[fieldKey]}</Box>
                    </span>
                    <span>
                        {
                            (props.audience[fieldKey as keyof AudienceDetail] as Array<any>).map((fieldDetails: any, index) => (
                                <span className="badge-spacer" key={index}>
                                    <Badge color="blue">{fieldDetails.profile_name}</Badge>
                                </span>
                            ))
                        }
                    </span>
                </div>
            )
        } else if (fieldMap[fieldKey] === 'reports') {
            const reportInfo: any = props.audience[fieldKey as keyof AudienceDetail]
            if (reportInfo && (reportInfo.direct || []).length > 0) {
                return (
                    <div className="audience-badge-area" key={mainIndex}>
                        <span className="audience-badge-label">
                            <Box fontSize="body-s" color="text-body-secondary">{labelMap[fieldKey]} - Direct</Box>
                        </span>
                        <span>
                            {
                                reportInfo.direct.map((reportee: string, index: number) => (
                                    <span className="badge-spacer" key={index}>
                                        <Badge color="blue">{reportee}</Badge>
                                    </span>
                                ))
                            }
                        </span>
                    </div>
                )
            } else if (reportInfo && (reportInfo.indirect || []).length > 0) {
                return (
                    <div className="audience-badge-area" key={mainIndex}>
                        <span className="audience-badge-label">
                            <Box fontSize="body-s" color="text-body-secondary">{labelMap[fieldKey]} - Indirect</Box>
                        </span>
                        <span>
                            {
                                reportInfo.indirect.map((reportee: string, index: number) => (
                                    <span className="badge-spacer" key={index}>
                                        <Badge color="blue">{reportee}</Badge>
                                    </span>
                                ))
                            }
                        </span>
                    </div>
                )
            }
        } else {
            return (<span key={mainIndex}></span>)
        }
    }

    const handleEditAudience = useCallback(async () => {
        dispatch(setAudienceVisible(true));
        await dispatch(audienceAttributesAsync(props.audience.audience_type));
        dispatch(editAudience(props.currentIndex));
    }, [dispatch, props.audience, props.currentIndex]);

    const handleDeleteAudience = useCallback(() => {
        //dispatch delete request. No notification needed when in edit mode
        dispatch(deleteAudience(props.currentIndex));
        setVisible(false);
    }, [dispatch, props.currentIndex]);

    const [visible, setVisible] = useState<boolean>(false);

    return (
        <div className="audience-card-container">
            <Modal
                onDismiss={() => dispatch(setAudienceVisible(false))}
                visible={visible}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
                            <Button variant="primary" onClick={handleDeleteAudience}>Confirm Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Audience Deletion"
            >
                <Box fontSize="heading-m">Are you sure you wish to delete this audience?</Box>
            </Modal>
            <div className="audience-card-label">
                <SpaceBetween size='xs'>
                    <div className='audience-action-area'>
                        <Box fontSize="heading-s" color="text-body-secondary">Total audience in this selection : <b dangerouslySetInnerHTML={{ __html: audienceCount }}></b></Box>
                        <div style={{ display: editingEnabled ? 'block' : 'none' }}>
                            <Button disabled={!editingEnabled} onClick={handleEditAudience}>Edit</Button>
                            &nbsp;&nbsp;
                            <Button disabled={!editingEnabled} onClick={() => setVisible(true)}>Delete</Button>
                        </div>
                        {
                            audienceLoadingStatus === 'loading' ?
                                (
                                    <Box fontSize='heading-m'>
                                        <Spinner /> Loading . . .
                                    </Box>
                                ) :
                                (
                                    <></>
                                )
                        }
                    </div>
                    <span>
                        {
                            editingEnabled ? "Audience can only be downloaded after saving changes" : ""
                        }
                    </span>
                    <span>
                        {
                            !editingEnabled && audienceCount === "NA" ? "It takes 15 minutes for Audience count and CSV to be generated" : ""
                        }
                    </span>
                    <div className='audience-action-area'>
                        <Box variant="awsui-key-label" fontSize="heading-s">{props.audience.audience_type}</Box>
                        <span style={{ display: !editingEnabled ? 'block' : 'none' }}>
                            <Button onClick={handleDownloadCsv} disabled={editingEnabled || audienceCount === "NA" || !adminCheck || audienceLoadingStatus !== 'idle'}>
                                Download CSV
                            </Button>
                        </span>
                    </div>
                </SpaceBetween>

            </div>
            <div className="audience-card-content">
                <SpaceBetween size="xs">
                    {
                        Object.keys(fieldMap).map(fieldHandler)
                    }
                </SpaceBetween>
            </div>
        </div>
    )
}

export default AudienceCard;