import './trainingconfiguration.css';
import { Outlet, useLocation, useNavigate, } from "react-router-dom";
import { Box, Button, Link, Modal, SpaceBetween, Wizard } from '@amzn/awsui-components-react';
import { useCallback, useEffect, useState } from 'react';
import CancelPopup from './cancelpopup';
import { cancelLMSTraining, saveTrainingDetailsAsync, selectConfigurationSaveStatus, selectLMSTraining, selectSaveId, setSaveStatus, setValidationStatus, timeMultiplier } from '../../../features/trainingConfiguration';
import { TrainingMetadataSelected, resetTrainingDetail, selectedTrainingDetail } from '../../../features/trainingDetails';
import { setFilterText } from '../../../features/fetchTraining';
import { resetSchedule, selectSchedule } from '../../../features/schedule';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { resetAudience, selectAudienceList } from '../../../features/audience';
import { sendNotification } from '../../../features/notification';
import { Auth } from 'aws-amplify';
const routeList = ["fetchtraining", "verifytraining", "trainingdetails", "trainingaudience", "trainingschedule"];

function TrainingConfiguration() {


    const location = useLocation();
    const defaultStep = Math.max(0, routeList.indexOf(location.pathname.split("/").pop() || ''));

    const [
        activeStepIndex,
        setActiveStepIndex
    ] = useState(defaultStep);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const lmsTraining = useAppSelector(selectLMSTraining);
    const schedule = useAppSelector(selectSchedule);
    const audience = useAppSelector(selectAudienceList);
    const trainingDetails = useAppSelector(selectedTrainingDetail);

    const [username, setUser] = useState<string>("");

    useEffect(() => {
        const currentStep = Math.max(0, routeList.indexOf(location.pathname.split("/").pop() || ''));
        setActiveStepIndex(currentStep);
    }, [location, setActiveStepIndex]);

    const saveStatus = useAppSelector(selectConfigurationSaveStatus);
    const saveId = useAppSelector(selectSaveId);

    useEffect(() => {
        if (saveStatus === "failed") {
            dispatch(sendNotification(
                {
                    type: 'error',
                    content: 'Training Configuration not saved. Please report if error persists.',
                    statusIconAriaLabel: 'error',
                    dismissLabel: 'Dismiss message',
                    dismissible: true
                }
            ))
            dispatch(setSaveStatus('idle'));
        } else if (saveStatus === "success") {
            dispatch(sendNotification(
                {
                    type: 'success',
                    content: 'Training Configuration created successfully. Redirecting to summary',
                    statusIconAriaLabel: 'success',
                    dismissLabel: 'Dismiss message',
                    dismissible: true
                }
            ))
            dispatch(setFilterText(""));
            dispatch(cancelLMSTraining());
            dispatch(resetTrainingDetail());
            dispatch(resetSchedule());
            dispatch(resetAudience());
            navigate(`/traininglibrary/trainingsummary/${saveId}`)
        }
    }, [saveStatus, saveId, navigate, dispatch]);



    const onNavigate = useCallback(
        (e: any) => {
            e.preventDefault();
            let conditionCheck: boolean = false;
            switch (activeStepIndex) {
                case 0:
                    conditionCheck = lmsTraining.id === "default-init-code"
                    break;
                case 2:
                    conditionCheck = Object.keys(trainingDetails).filter((trainingKey) => {
                        return trainingDetails[trainingKey as keyof TrainingMetadataSelected] !== null
                    }).length < 6;
                    break;

                default:
                    conditionCheck = false;
                    break;
            }

            //For current form set error to 'true' if conditionCheck 'true'
            dispatch(setValidationStatus(conditionCheck));
            if (!conditionCheck || (e.detail.requestedStepIndex < activeStepIndex)) {
                navigate(routeList[e.detail.requestedStepIndex], { replace: true });
            }
        },
        [navigate, dispatch, lmsTraining, activeStepIndex, trainingDetails]
    );

    const [visible, setVisible] = useState(false);

    const onCancel = useCallback(
        (e: any) => {
            e.preventDefault();
            setVisible(false);
            navigate('/', { replace: true });
            dispatch(setFilterText(""));
            dispatch(cancelLMSTraining());
            dispatch(resetTrainingDetail());
            dispatch(resetSchedule());
            dispatch(resetAudience());
        },
        [navigate, dispatch, setVisible]
    );

    const onConfigSubmit = useCallback(() => {

        let conditionCheck: boolean = false;
        if (schedule.schedule_type === "ONCE" && schedule.due_date) {
            if (schedule.due_date.length === 10) {
                //Full date value is filled, we should validate
                let localDate = new Date();
                localDate.setDate(localDate.getDate() + 7);
                conditionCheck = new Date(schedule.due_date) < localDate
            } else {
                conditionCheck = true;
            }
        } else if (schedule.schedule_type === "RECURRENCE" &&
            schedule.due_date_period.type &&
            schedule.due_date_period.value &&
            schedule.frequency.type &&
            schedule.frequency.value
        ) {
            const due_date_days = parseInt(schedule.due_date_period.value) * timeMultiplier(schedule.due_date_period.type.value);
            const frequency_days = parseInt(schedule.frequency.value) * timeMultiplier(schedule.frequency.type.value);
            if (frequency_days > due_date_days) {
                conditionCheck = false;
            } else {
                conditionCheck = true;
            }
        } else {
            conditionCheck = true;
        }
        //For current (schedule) form set error to 'true' if conditionCheck 'true'
        dispatch(setValidationStatus(conditionCheck));
        if (!conditionCheck) {
            dispatch(saveTrainingDetailsAsync({
                lmsTraining,
                schedule,
                audience,
                trainingDetails,
                username
            }));
        }
    }, [dispatch, lmsTraining, schedule, audience, trainingDetails, username]);

    useEffect(() => {
        async function getToken() {
            const username = (await Auth.currentSession()).getIdToken().payload.preferred_username;
            setUser(username);
        }

        if (!username) {
            getToken()
        }
    })

    const nextStepText = "Next";
    const submitText = "Confirm and create";

    return (
        <div className="training-configuration-wrapper">
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                closeAriaLabel="Close modal"
                size="medium"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
                            <Button variant="primary" onClick={(e) => onCancel(e)}>Confirm</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Confirm"
            >
                <CancelPopup></CancelPopup>
            </Modal>
            <Wizard
                i18nStrings={{
                    stepNumberLabel: stepNumber =>
                        `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    skipToButtonLabel: (step, stepNumber) =>
                        `Skip to ${step.title}`,
                    navigationAriaLabel: "Steps",
                    cancelButton: "Cancel",
                    previousButton: "Previous",
                    nextButton: nextStepText,
                    submitButton: submitText,
                    optional: "optional"
                }}
                // TO-DO : Connect below prop to the actual form state with  API
                isLoadingNextStep={saveStatus === "loading"}
                onNavigate={onNavigate}
                onCancel={() => setVisible(true)}
                onSubmit={onConfigSubmit}
                activeStepIndex={activeStepIndex}
                allowSkipTo
                steps={[
                    {
                        title: "Find Training",
                        content: <Outlet />
                    },
                    {
                        title: "Verify Details",
                        content: <Outlet />
                    },
                    {
                        title: "Training Details",
                        content: (
                            <>
                                <div className="training-title-track">
                                    <Link
                                        href={`https://knet.csod.com/ui/lms-learning-details/app/course/${lmsTraining.id}`}
                                        external>
                                        <div className="training-title-label">{lmsTraining.title}</div>
                                    </Link>
                                </div>
                                <hr className="training-title-separator" />
                                <Outlet />
                            </>

                        )
                    },
                    {
                        title: "Training Audience",
                        content: (
                            <>
                                <div className="training-title-track">
                                    <Link
                                        href={`https://knet.csod.com/ui/lms-learning-details/app/course/${lmsTraining.id}`}
                                        external>
                                        <div className="training-title-label">{lmsTraining.title}</div>
                                    </Link>
                                </div>
                                <hr className="training-title-separator" />
                                <Outlet />
                            </>

                        )
                    },
                    {
                        title: "Training Schedule",
                        content: (
                            <>
                                <div className="training-title-track">
                                    <Link
                                        href={`https://knet.csod.com/ui/lms-learning-details/app/course/${lmsTraining.id}`}
                                        external>
                                        <div className="training-title-label">{lmsTraining.title}</div>
                                    </Link>
                                </div>
                                <hr className="training-title-separator" />
                                <Outlet />
                            </>

                        )
                    }
                ]}
            />
        </div>
    )
}

export default TrainingConfiguration;
