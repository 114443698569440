import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { Auth } from "aws-amplify";
import { DeleteTrainingInfo } from ".";

export async function fetchSummary(trainingId: string = "dummy value") {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const configResponse = await axios.post(`${BASE_URL}/config/v1/training_configuration`,
        {
            training_id: trainingId
        }, requestHeaders);
    const source_id = configResponse.data.training_configuration.training_source_id;
    const lmsResponse = await axios.get(`${BASE_URL}/lms/v1/training_details?id=${source_id}&lms=KNET`, requestHeaders)
    return {
        config: configResponse.data,
        lmsInfo: lmsResponse.data
    }
}

export async function deleteTraining(trainingInfo: DeleteTrainingInfo) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };

    const requestBody = {
        training_id: trainingInfo.trainingId,
        version: trainingInfo.version
    }

    const { data } = await axios.post(`${BASE_URL}/config/v1/delete_training`, requestBody, requestHeaders);

    return data;
}