import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { Auth } from "aws-amplify";

export async function fetchAudienceAttribute(audience_type: string = "Operations") {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.get(`${BASE_URL}/employee/v1/attribute_values?audience_type=${audience_type}`, requestHeaders);

    return data;
}

export async function fetchSuggestionsJobTitle(filterString: string) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const queryParam = {
        type: "WILD_CARD",
        attribute: "name",
        value: `${filterString}*`
    };
    const queryString = encodeURIComponent(JSON.stringify(queryParam));
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.get(`${BASE_URL}/employee/v1/search-job-profiles?query=${queryString}`, requestHeaders);

    return data;
}

export async function fetchSuggestionsEmployee(filterString: string) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.get(`${BASE_URL}/employee/v1/type-ahead-manager?query=${filterString}`, requestHeaders);

    return data;
}

export async function fetchAudienceCount(audience_id: string) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.get(`${BASE_URL}/employee/v1/user_count?id=${audience_id}`, requestHeaders);

    return data;
}

export async function fetchAudienceCsvURL(audience_id: string, training_id: string) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.get(`${BASE_URL}/employee/v1/download_audience_csv?audience_id=${audience_id}&training_id=${training_id}`, requestHeaders);

    return data;
}