import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import { Pagination, SpaceBetween, Container, Modal, TextFilter, CollectionPreferences } from "@amzn/awsui-components-react";
import './traininglibrary.css';
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import LibraryFilters from "./libraryfilters";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    libraryRecordsAsync,
    loadAudienceMetatdataAsync,
    resetLibraryFilters,
    selectDescendingSorting,
    selectLibraryMaxPages,
    selectLibraryPage,
    selectLibraryRecords,
    selectLibrarySearchText,
    selectLibraryStatus,
    selectPageSize,
    selectPrimaryFilters,
    selectSortingColumn,
    setDescendingSorting,
    setLibrarySearchText,
    setPage,
    setPageSize,
    setSortingColumn
} from "../../../features/library";
import LibraryMetrics from "./librarymetrics";
import { selectAuthAdmin } from "../../../features/auth";

function TrainingLibrary() {

    const libraryStatus = useAppSelector(selectLibraryStatus);
    const libraryRecords = useAppSelector(selectLibraryRecords);
    const currentLibraryPage = useAppSelector(selectLibraryPage);
    const maxLibraryPages = useAppSelector(selectLibraryMaxPages);
    const sortingColumn = useAppSelector(selectSortingColumn);
    const descendingSorting = useAppSelector(selectDescendingSorting);
    const primaryFilters = useAppSelector(selectPrimaryFilters);
    // const secondaryFilters = useAppSelector(selectSecondaryFilters);
    const librarySearchText = useAppSelector(selectLibrarySearchText);
    const pageSize = useAppSelector(selectPageSize);
    const adminCheck = useAppSelector(selectAuthAdmin);

    const [visible, setVisible] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (libraryRecords === null && libraryStatus !== 'failed') {
            dispatch(libraryRecordsAsync());
        }
    })

    const handlePagination = useCallback((e: any) => {
        dispatch(setPage(e.detail.currentPageIndex));
        dispatch(libraryRecordsAsync());
    }, [dispatch])

    const handleSorting = useCallback((e: any) => {
        const sortingColumn = e.detail.sortingColumn;
        delete sortingColumn['cell'];
        dispatch(setSortingColumn(sortingColumn));
        dispatch(setDescendingSorting(e.detail.isDescending));
        dispatch(libraryRecordsAsync());
    }, [dispatch]);

    const handleModalFilter = useCallback(() => {
        setVisible(false);
        dispatch(setPage(1));
        dispatch(libraryRecordsAsync());
    }, [dispatch]);

    const handleFilterReset = useCallback(() => {
        dispatch(resetLibraryFilters());
        dispatch(setLibrarySearchText(""));
        dispatch(loadAudienceMetatdataAsync());
        dispatch(libraryRecordsAsync());
    }, [dispatch]);

    const updateTextSearch = useCallback((e: any) => {
        e.preventDefault();
        dispatch(setLibrarySearchText(e.detail.filteringText));
    }, [dispatch]);

    const handleTextSearch = useCallback((e: any) => {
        e.preventDefault();
        dispatch(setPage(1))
        dispatch(libraryRecordsAsync());
    }, [dispatch]);

    const handleTableSettingsChange = useCallback((e: any) => {
        dispatch(setPageSize(e.detail.pageSize));
        dispatch(setPage(1));
        dispatch(libraryRecordsAsync());
    }, [dispatch]);

    return (
        <div>
            <Table
                preferences={
                    <CollectionPreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={{
                            pageSize
                        }}
                        pageSizePreference={{
                            title: "Page size",
                            options: [
                                { value: 10, label: "10 trainings" },
                                { value: 30, label: "30 trainings" },
                                { value: 50, label: "50 trainings" }
                            ]
                        }}
                        onConfirm={handleTableSettingsChange}
                    />
                }
                sortingColumn={sortingColumn}
                sortingDescending={descendingSorting}
                onSortingChange={handleSorting}
                columnDefinitions={[
                    {
                        id: "training_name",
                        header: "Training Name",
                        cell: e => <Link to={`/traininglibrary/trainingsummary/${e.training_id}`}>{e.training_name}</Link>,
                        maxWidth: '200px',
                        sortingField: 'training_name'
                    },
                    {
                        id: "training_type",
                        header: "Type",
                        cell: e => e.training_type,
                        sortingField: 'training_type'
                    },
                    {
                        id: "ms_level",
                        header: "WHS Management System",
                        cell: e => e.ms_level,
                        sortingField: 'ms_level'
                    },
                    {
                        id: "training_program",
                        header: "Program",
                        cell: e => e.training_program_name,
                        sortingField: 'program_name',
                        maxWidth: '200px'
                    },
                    {
                        id: "training_category",
                        header: "Category",
                        cell: e => e.training_category,
                        sortingField: 'training_category'
                    },
                    {
                        id: "regulatory_status",
                        header: "Regulatory Status",
                        cell: e => e.regulatory_requirement ? "Yes" : "No",
                        sortingField: 'regulatory_requirement'
                    }
                ]}
                items={libraryRecords || []}
                loading={libraryStatus === "loading"}
                loadingText="Loading training list"
                stickyHeader
                variant="full-page"
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No trainings</b>
                        <Box
                            padding={{ bottom: "s" }}
                            variant="p"
                            color="inherit"
                        >
                            No applicable trainings as per the search criteria. Please refine your search
                        </Box>
                        <Button onClick={() => navigate('/trainingconfiguration')} disabled={!adminCheck}>Map Training</Button>
                    </Box>
                }
                filter={
                    <div className="input-container">
                        <div className="input-filter">
                            <TextFilter
                                filteringPlaceholder="Search training by name"
                                filteringText={librarySearchText}
                                onChange={updateTextSearch}
                            />
                            Click on filter button to search for specific training.
                        </div>
                        <div>
                            <Button variant="primary" onClick={handleTextSearch} disabled={libraryStatus === 'loading'}>
                                Search Training
                            </Button>
                        </div>
                    </div>

                }
                header={
                    <div>
                        <Header variant="awsui-h1-sticky"
                            actions={
                                <div style={{ paddingBottom: '12px' }}>
                                    <SpaceBetween direction="horizontal" size="xs">
                                        {/* <Button disabled>Drafts (disabled)</Button> */}
                                        <Button variant="primary" onClick={() => navigate('/trainingconfiguration')} disabled={!adminCheck}>
                                            Map Training
                                        </Button>
                                        &nbsp;
                                        <div className="select-filter">
                                            <Button onClick={() => setVisible(true)} disabled={libraryStatus === 'loading'}>Filter</Button>
                                        </div>
                                        <div className="select-filter">
                                            <Button disabled={libraryStatus === 'loading'} onClick={handleFilterReset}>Reset Filters</Button>
                                        </div>
                                    </SpaceBetween>
                                </div>
                            }
                        >
                            Training Library
                        </Header>
                        <Container>
                            <div className="highlight-container">
                                <div className="primary-highlight">
                                    {
                                        primaryFilters.map((filterInfo, index) => (
                                            <LibraryMetrics filterInfo={filterInfo} key={index} />
                                        ))
                                    }
                                </div>
                                {/* <div className="secondary-highlight">
                                    {
                                        secondaryFilters.map((filterInfo, index) => (
                                            <LibraryMetrics filterInfo={filterInfo} key={index} />
                                        ))
                                    }
                                </div> */}
                            </div>

                        </Container>
                    </div>

                }
                pagination={
                    <div>
                        <Pagination
                            currentPageIndex={currentLibraryPage}
                            pagesCount={maxLibraryPages}
                            // Note - We are disabling open-ended pagination to check if calculating pages method works correctly
                            // openEnd={libraryRecords?.length !== 0}
                            ariaLabels={{
                                nextPageLabel: "Next page",
                                previousPageLabel: "Previous page",
                                pageLabel: pageNumber =>
                                    `Page ${pageNumber} of all pages`
                            }}
                            onChange={handlePagination}
                            disabled={libraryStatus === 'loading'}
                        />

                    </div>
                }
            />
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
                            <Button variant="primary" onClick={handleModalFilter}>Apply Filters</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Training Filters"
            >
                <LibraryFilters />
            </Modal>
        </div>
    );
}

export default TrainingLibrary;