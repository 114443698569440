import { Cards, ContentLayout, Header } from '@amzn/awsui-components-react';
import './Landing.css';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { selectAuthAdmin, selectAuthStatus } from '../../../features/auth';
import { QUICKSIGHT_URL } from '../../../config/commonConstants';

function Landing() {

    const adminCheck = useAppSelector(selectAuthAdmin);
    const authStatus = useAppSelector(selectAuthStatus);

    const configurationTileText: string = adminCheck ?
        "Map Training" :
        authStatus === 'loading' ?
            "Checking Permission ..." : "Map Training Access Denied"

    return (
        <div className="landing-page-wrapper">
            <ContentLayout
                header={
                    <Header
                        variant="h1"
                    >
                        Minerva Home
                    </Header>

                }
            >
                <Cards
                    ariaLabels={{
                        itemSelectionLabel: (e, n) => `select ${n.name}`,
                        selectionGroupLabel: "Item selection"
                    }}
                    cardDefinition={{
                        header: (item: any) => (
                            <Link to={item.navPath} className={item.disabled ? `navtile navtile-disabled` : `navtile`} target={item.external ? '_blank' : '_self'}>
                                <img
                                    className='navtile-logo'
                                    src={item.iconPath} alt={item.alt} />
                                <h3 className='navtile-label'>{item.name}</h3>
                            </Link>
                        )
                    }}
                    cardsPerRow={[
                        { cards: 3 }
                    ]}
                    items={[
                        {
                            name: "Training Reports",
                            alt: "Training Reports",
                            iconPath: "/1@2x.png",
                            navPath: QUICKSIGHT_URL,
                            disabled: false,
                            external: false
                        },
                        {
                            name: "Training Library",
                            alt: "Training Library",
                            iconPath: "/2@2x.png",
                            navPath: "traininglibrary"
                        },
                        {
                            name: configurationTileText,
                            alt: "Map Training",
                            iconPath: "/3@2x.png",
                            navPath: "trainingconfiguration/fetchtraining",
                            disabled: !adminCheck
                        }
                    ]}
                />
            </ContentLayout>
        </div>
    )
}

export default Landing;