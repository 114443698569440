import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchTraining } from "./fetchTrainingAPI";

export interface TrainingDetail {
    id: string;
    lms: string;
    title: string;
    updated_on?: string;
    version: string;
    description?: string;
    description_html?: string;
    duration_hours?: number;
    duration?: number;
}

export interface FetchTrainingState {
    trainingList: TrainingDetail[];
    filterText: string;
    status: 'idle' | 'loading' | 'failed' | 'no-match';
}

const initialState: FetchTrainingState = {
    trainingList: [],
    filterText: '',
    status: 'idle'
}

export const selectFetchTrainingText = (state: RootState) => state.fetchTraining.filterText;
export const selectFetchTrainingList = (state: RootState) => state.fetchTraining.trainingList;
export const selectFetchTrainingStatus = (state: RootState) => state.fetchTraining.status;

export const filterTrainingAsync = createAsyncThunk(
    'fetchTraining/filterTrainingAsync',
    async (filterText: string) => {
        if (filterText === "" || filterText === " ") {
            return [];
        }
        const response = await fetchTraining(filterText);
        response.trainings.forEach((training: TrainingDetail) => {
            training.description = training.description?.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>').replaceAll('\\t', '&#9;').replace("NULL", "Training Description Unavailable from KNET");
        })

        return response.trainings;
    }
);

export const fetchTrainingSlice = createSlice({
    name: 'fetchTraining',
    initialState,
    reducers: {
        setFilterText: (state, action: PayloadAction<string>) => {
            state.filterText = action.payload;
            if (action.payload === "") {
                state.trainingList = [];
            }
        },
        setFetchTrainingStatus: (state, action: PayloadAction<any>) => {
            state.status = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(filterTrainingAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(filterTrainingAsync.fulfilled, (state, action) => {
                state.status = action.payload.length === 0 ? 'no-match' : 'idle';
                state.trainingList = action.payload;
            })
            .addCase(filterTrainingAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});


export const { setFilterText, setFetchTrainingStatus } = fetchTrainingSlice.actions;

export default fetchTrainingSlice.reducer;