import { ContentLayout, SpaceBetween, Header, Button, Container, Box, Grid, ColumnLayout, Link, Spinner, Modal } from "@amzn/awsui-components-react";
import './trainingsummary.css';
import AudienceCard from "../trainingconfiguration/forms/trainingaudience/audiencecard";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteTrainingAsync, fetchSummaryAsync, selectSummary, selectSummaryStatus, setSummaryStatus } from "../../../features/summary";
import { useCallback, useEffect, useState } from "react";
import { AudienceDetail, resetAudience, setAudienceList } from "../../../features/audience";
import { Link as LinkDom } from "react-router-dom";
import DeletePopup from "./deletepopup";
import { libraryRecordsAsync } from "../../../features/library";
import { sendNotification } from "../../../features/notification";
import { cancelLMSTraining, setLMSTraining, setSaveId, setVersion } from "../../../features/trainingConfiguration";
import { trainingDetailsFormFields } from "../trainingconfiguration/forms/trainingdetails";
import { TrainingDetailFormOption, resetTrainingDetail, setTrainingDetailOption } from "../../../features/trainingDetails";
import { resetSchedule, setDueDate, setDueDateType, setDueDateValue, setRecurenceValue, setRecurrenceType, setScheduleType } from "../../../features/schedule";
import { setFilterText } from "../../../features/fetchTraining";
import { selectAuthAdmin, selectIsLoggedIn } from "../../../features/auth";
import { LOGIN_STATES, QUICKSIGHT_URL } from "../../../config/commonConstants";
import { formatDate, parseDuration } from "../../../features/fetchTraining/fetchTrainingHelper";

const trainingDetailFields = [
    {
        key: 'training_category',
        label: 'Training Category'
    },
    {
        key: 'training_modality',
        label: 'Training Mode'
    },
    {
        key: 'ms_level',
        label: 'WHS Management System'
    },
    {
        key: 'training_type',
        label: 'Training Type'
    },
    {
        key: 'training_program_name',
        label: 'Training Program'
    },
    {
        key: 'regulatory_requirement',
        label: 'Regulatory Requirement'
    }
];

function handleBoolean(value: any) {
    if (typeof value === "boolean") {
        return value ? "Yes" : "No";
    } else {
        return value;
    }

}

function TrainingSummary() {
    const location = useLocation();
    const navigate = useNavigate();
    const trainingId = location.pathname.split("/").pop() || "";
    const dispatch = useAppDispatch();
    const trainingInfo = useAppSelector(selectSummary);
    const summaryStatus = useAppSelector(selectSummaryStatus);
    const adminCheck = useAppSelector(selectAuthAdmin);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    const defaultMessage = (lms: string) => {
        return (
            <i>Unavailable from {lms}</i>
        );
    }

    useEffect(() => {
        if (isLoggedIn === LOGIN_STATES.loggedIn) {
            dispatch(fetchSummaryAsync(trainingId))
        }
    }, [dispatch, trainingId, isLoggedIn])

    const conditionCheck = (trainingInfo === null || trainingInfo.config === undefined || trainingInfo.config.training_configuration.training_id !== trainingId) && summaryStatus !== "failed";

    const [visible, setVisible] = useState(false);

    const handleDeleteCallback = useCallback(async () => {
        const deleteInfo = {
            trainingId: trainingId,
            version: parseInt(trainingInfo.config.training_configuration.version)
        }
        const training_name = trainingInfo.config.training_configuration.training_name;
        const deleteResponse = await dispatch(deleteTrainingAsync(deleteInfo));
        if (deleteResponse.payload.message.includes("success")) {
            dispatch(sendNotification(
                {
                    type: 'success',
                    content: `Training configuration is deleted successfully - '${training_name}'`,
                    statusIconAriaLabel: 'success',
                    dismissLabel: 'Dismiss message',
                    dismissible: true
                }
            ))
            dispatch(libraryRecordsAsync());
            navigate('/traininglibrary')
        } else {
            dispatch(sendNotification(
                {
                    type: 'error',
                    content: `Unable to delete training configuration - '${training_name}'`,
                    statusIconAriaLabel: 'error',
                    dismissLabel: 'Dismiss message',
                    dismissible: true
                }
            ))
            console.log("Unable to delete training");
        }
    }, [dispatch, navigate, trainingId, trainingInfo]);

    useEffect(() => {
        if (summaryStatus === "failed") {
            setTimeout(() => {
                dispatch(libraryRecordsAsync());
                dispatch(setSummaryStatus("idle"));
                navigate('/traininglibrary');
            }, 5000);
        }
    }, [summaryStatus, dispatch, navigate]);

    const handleEditCallback = useCallback(() => {
        dispatch(setFilterText(""));
        dispatch(cancelLMSTraining());
        dispatch(resetTrainingDetail());
        dispatch(resetSchedule());
        dispatch(resetAudience());
        const trainingConfiguration = trainingInfo.config.training_configuration;
        //NOTE - This may require different handling if we support multiple schedules
        const recurrenceInfo = trainingInfo.config.recurrence_training_schedules[0];
        dispatch(setLMSTraining(trainingInfo.lmsInfo));
        dispatch(setSaveId(trainingConfiguration.training_id));
        dispatch(setVersion(trainingConfiguration.version));
        trainingDetailsFormFields.forEach((detailsFormField) => {
            let trainingLabel: string;
            let trainingValue: string;
            if (detailsFormField.id === "regulatory_requirement") {
                trainingLabel = trainingConfiguration[detailsFormField.id] ? "Yes" : "No"
                trainingValue = trainingConfiguration[detailsFormField.id];
            } else if (detailsFormField.id === "training_program") {
                trainingLabel = trainingConfiguration["training_program_name"];
                trainingValue = trainingConfiguration["training_program_name"];
            } else {
                trainingLabel = trainingConfiguration[detailsFormField.id];
                trainingValue = trainingConfiguration[detailsFormField.id];
            }
            const trainingDetailsFormOption: TrainingDetailFormOption = {
                label: trainingLabel,
                value: trainingValue
            };

            dispatch(setTrainingDetailOption({
                id: detailsFormField.id,
                value: trainingDetailsFormOption
            }));
        });
        dispatch(setAudienceList(trainingConfiguration.audience_list));
        dispatch(setScheduleType(trainingConfiguration.schedule_type));
        if (trainingConfiguration.schedule_type === "ONCE") {
            dispatch(setDueDate(trainingConfiguration.next_due_date.split("T")[0]));
        } else {
            dispatch(setDueDateType({
                label: recurrenceInfo.due_date_period.type,
                value: recurrenceInfo.due_date_period.type
            }));
            dispatch(setDueDateValue(recurrenceInfo.due_date_period.value));
            dispatch(setRecurrenceType({
                label: recurrenceInfo.frequency.type,
                value: recurrenceInfo.frequency.type
            }));
            dispatch(setRecurenceValue(recurrenceInfo.frequency.value));
        }
        navigate('/trainingconfiguration/verifytraining');
    }, [dispatch, navigate, trainingInfo]);

    return conditionCheck ?
        (
            <div className="spinner-container">
                <Spinner size="large"></Spinner>
                <Box fontSize="heading-m">Loading Training Summary ...</Box>
            </div>
        ) :
        summaryStatus === "failed" ?
            (
                <div className="summary-fail-container">
                    <Box fontSize="heading-l">Unable to load training. Training may have been deleted.</Box>
                    <Box fontSize="heading-l">You will be redirected to the training Library</Box>
                </div>
            ) :
            (
                <ContentLayout
                    header={
                        <SpaceBetween size="m">
                            <Header
                                variant="h1"
                            >
                                {trainingInfo.lmsInfo.title}
                            </Header>
                        </SpaceBetween>
                    }
                >
                    <Modal
                        onDismiss={() => setVisible(false)}
                        visible={visible}
                        closeAriaLabel="Close modal"
                        size="large"
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="link" onClick={() => setVisible(false)} disabled={summaryStatus === "loading"}>Cancel</Button>
                                    <Button variant="primary" onClick={handleDeleteCallback} disabled={summaryStatus === "loading"}>{summaryStatus === "loading" ? <Spinner /> : <></>}Confirm Delete</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header="Confirm Training Deletion"
                    >
                        <DeletePopup training_name={trainingInfo.config.training_configuration.training_name} />
                    </Modal>
                    <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                        <Container
                            header={
                                <Header
                                    variant="h2"
                                >
                                    {trainingInfo.lmsInfo.title}
                                </Header>
                            }
                        >
                            <Box variant="p">
                                <span dangerouslySetInnerHTML={{ __html: trainingInfo.lmsInfo.description || trainingInfo.lmsInfo.description_html || 'Training Description Unavailable' }}></span>
                            </Box>
                            <Box color="text-status-inactive">
                                Last Updated in Minerva : {formatDate(trainingInfo.config.training_configuration.updated_on) || "Unavailable"}
                            </Box>
                            <hr className="action-separator" />
                            <div className="summary-label">
                                <Box variant="h3" fontWeight="bold">Source Platform Details</Box>
                            </div>
                            <Box variant="p">LMS  : {trainingInfo.lmsInfo.lms || defaultMessage(trainingInfo.lmsInfo.lms)}</Box>
                            <Box variant="p">Version  : {trainingInfo.lmsInfo.version || defaultMessage(trainingInfo.lmsInfo.lms)}</Box>
                            <Box variant="p">Training Type  : {trainingInfo.lmsInfo.training_type || defaultMessage(trainingInfo.lmsInfo.lms)}</Box>
                            <Box variant="p">Provider  : {trainingInfo.lmsInfo.provider_name || defaultMessage(trainingInfo.lmsInfo.lms)}</Box>
                            <Box variant="p">Duration : {parseDuration(trainingInfo.lmsInfo.duration_in_iso) || defaultMessage(trainingInfo.lmsInfo.lms)}</Box>
                            <Box variant="p">Updated On  : {formatDate(trainingInfo.lmsInfo.updated_date) || defaultMessage(trainingInfo.lmsInfo.lms)}</Box>
                            <Box variant="p">Updated By  : {trainingInfo.lmsInfo.updated_by || defaultMessage(trainingInfo.lmsInfo.lms)}</Box>
                            <div className="summary-label">
                                <Box variant="h3" fontWeight="bold">Available Language(s)</Box>
                            </div>
                            <ColumnLayout columns={3} disableGutters>
                                {
                                    trainingInfo.lmsInfo.languages_available.map((language: string, index: number) => (
                                        <div key={index} className="language-label">
                                            <Box color="text-body-secondary" fontSize="heading-s">{language}</Box>
                                        </div>
                                    ))
                                }
                            </ColumnLayout>
                            <hr className="action-separator" />
                            {
                                trainingInfo.lmsInfo.deep_link.map((training_link: string, index: number) => (
                                    <Link
                                        key={index}
                                        external
                                        externalIconAriaLabel="Opens in a new tab"
                                        href={training_link}
                                    >
                                        See Training in LMS
                                    </Link>
                                ))
                            }
                            <hr className="action-separator" />
                            <div className="summary-label">
                                <Box variant="h3" fontWeight="bold">Minerva Library Info</Box>
                            </div>
                            <Box variant="p">Version : {trainingInfo.config.training_configuration.version}</Box>
                            <Box variant="p">Updated On : {formatDate(trainingInfo.config.training_configuration.created_on)}</Box>
                            <Box variant="p">Updated By : {trainingInfo.config.training_configuration.created_by}</Box>
                            <hr className="action-separator" />
                            <div className="summary-label">
                                <Box variant="h3" fontWeight="bold">Training Details</Box>
                            </div>
                            <ColumnLayout columns={2} disableGutters>
                                {
                                    trainingDetailFields.map((field, index) => (
                                        <div key={index} className="training-detail-container">
                                            <div className="training-detail-label">
                                                <Box display="inline-block" variant="p" color="text-status-inactive">{field.label}</Box>
                                            </div>
                                            <Box display="inline-block" variant="p">{handleBoolean(trainingInfo.config.training_configuration[field.key]).toString()}</Box>
                                        </div>
                                    ))
                                }
                            </ColumnLayout>
                            <div className="summary-label">
                                <Box variant="h3" fontWeight="bold">Audience</Box>
                            </div>
                            <SpaceBetween size="s">
                                {
                                    (trainingInfo.config.training_configuration.audience_list !== null) ?
                                        (
                                            trainingInfo.config.training_configuration.audience_list.map((audience: AudienceDetail, index: number) => {
                                                return (
                                                    //TODO: Audience card props must be added after audience API integration
                                                    <AudienceCard
                                                        key={index}
                                                        audience={audience}
                                                        trainingId={trainingInfo.config.training_configuration.training_id}
                                                        currentIndex={index}
                                                        updateTimestamp={trainingInfo.config.training_configuration.updated_on}
                                                    ></AudienceCard>
                                                )
                                            })
                                        ) :
                                        (
                                            <div>Audience not added</div>
                                        )
                                }
                            </SpaceBetween>
                            <div className="summary-label">
                                <Box variant="h3" fontWeight="bold">Training Schedule</Box>
                            </div>
                            {
                                trainingInfo.config.training_configuration.schedule_type === "ONCE" ?
                                    (
                                        <div>
                                            <div className="training-detail-label">
                                                <Box display="inline-block" variant="p" color="text-status-inactive">Due Date</Box>
                                            </div>
                                            <Box display="inline-block" variant="p">{formatDate(trainingInfo.config.training_configuration.next_due_date) || 'Unavailable'}</Box>
                                        </div>
                                    ) :
                                    (
                                        <></>
                                    )
                            }

                            {
                                trainingInfo.config.training_configuration.schedule_type === 'RECURRENCE' ?
                                    (
                                        <div>
                                            <div>
                                                <div className="training-detail-label">
                                                    <Box display="inline-block" variant="p" color="text-status-inactive">Frequency</Box>
                                                </div>
                                                <Box display="inline-block" variant="p">
                                                    Every&nbsp;
                                                    {trainingInfo.config.recurrence_training_schedules[0]['frequency']['value']}&nbsp;
                                                    {trainingInfo.config.recurrence_training_schedules[0]['frequency']['type']}
                                                </Box>
                                            </div>
                                            <div>
                                                <div className="training-detail-label">
                                                    <Box display="inline-block" variant="p" color="text-status-inactive">Due Date Time Period</Box>
                                                </div>
                                                <Box display="inline-block" variant="p">
                                                    {trainingInfo.config.recurrence_training_schedules[0]['due_date_period']['value']}&nbsp;
                                                    {trainingInfo.config.recurrence_training_schedules[0]['due_date_period']['type']}
                                                </Box>
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        <></>
                                    )

                            }
                        </Container>
                        <Container
                            header={
                                <Box variant="awsui-key-label">Actions</Box>
                            }
                        >
                            <SpaceBetween size={"xxs"}>
                                {/* <Button iconName="download" variant="link">
                                <div className="action-label">
                                    Download
                                </div>
                            </Button> */}
                                <hr className="action-separator" />
                                <Button iconName="delete-marker" variant="link" onClick={() => setVisible(true)} disabled={!adminCheck}>
                                    <div className="action-label">
                                        Delete
                                    </div>
                                </Button>
                                <hr className="action-separator" />
                                <Button iconName="edit" variant="link" onClick={handleEditCallback} disabled={!adminCheck}>
                                    <div className="action-label">
                                        Edit
                                    </div>
                                </Button>
                                <hr className="action-separator" />
                                <LinkDom to={QUICKSIGHT_URL}>
                                    <Button iconName="view-vertical" variant="link">
                                        <div className="action-label">
                                            Training Report
                                        </div>
                                    </Button>
                                </LinkDom>
                                <hr className="action-separator" />
                            </SpaceBetween>
                        </Container>
                    </Grid>
                </ContentLayout>
            );
}

export default TrainingSummary;