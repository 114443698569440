import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { LOGIN_STATES, REDUCER_STATES } from "../../config/commonConstants";

export interface AuthState {
    username: string;
    admin: boolean;
    status: REDUCER_STATES;
    isLoggedIn: LOGIN_STATES;
    redirectURI: string;
}

const initialState: AuthState = {
    username: 'johndoe',
    admin: false,
    status: REDUCER_STATES.IDLE,
    isLoggedIn: LOGIN_STATES.notLoggedIn,
    redirectURI: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuth: () => initialState,
        setUser: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setAdmin: (state, action: PayloadAction<boolean>) => {
            state.admin = action.payload;
            state.status = REDUCER_STATES.IDLE;
        },
        setAuthStatus: (state, action: PayloadAction<REDUCER_STATES>) => {
            state.status = action.payload;
        },
        setAuthLoggedIn: (state, action: PayloadAction<LOGIN_STATES>) => {
            state.isLoggedIn = action.payload;
        },
        setRedirectURI: (state, action: PayloadAction<string>) => {
            state.redirectURI = action.payload;
        }
    }
});

export const { resetAuth, setUser, setAdmin, setAuthStatus, setAuthLoggedIn, setRedirectURI } = authSlice.actions;

export const selectAuthUser = (state: RootState) => state.auth.username;
export const selectAuthAdmin = (state: RootState) => state.auth.admin;
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectRedirectURI = (state: RootState) => state.auth.redirectURI;

export default authSlice.reducer;