// A mock function to mimic making an async request for data

import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { Auth } from "aws-amplify";

export async function saveTrainingConfiguration(savePayload: any) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.post(`${BASE_URL}/config/v1/save_training`, savePayload, requestHeaders);

    if (data.hasOwnProperty('training_id')) {
        return data;
    } else if (data.message.includes("exists")) {
        throw new Error("Duplicate Training");
    } else {
        throw new Error("Unable to save configuration");
    }
}

export async function updateTrainingConfiguration(savePayload: any) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.post(`${BASE_URL}/config/v1/update_training`, savePayload, requestHeaders);

    if (data.hasOwnProperty('training_id')) {
        return data;
    } else {
        throw new Error("Unable to save configuration");
    }
}

export async function fetchTrainingDetails(trainingId: string = "dummy value") {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.get(`${BASE_URL}/lms/v1/training_details?id=${trainingId}&lms=KNET`, requestHeaders)
    return data;
}