import { Grid, Multiselect, RadioGroup } from "@amzn/awsui-components-react";
import { useCallback } from "react";
import './audienceform.css';
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { AudienceMetadata, employeeSuggestionsAsync, jobTitlesAsync, selectCurrentAudience, selectMetadata, selectReportsType, selectTypeaheadStatus, setAudience, setReportsType } from "../../../../../../../features/audience";

export interface AudienceFormProps {
    label: string;
    id: string;
    placeholder: string;
}

// TODO: Add support for different input types as a prop if needed by API
function AudienceForm(props: AudienceFormProps) {

    const dispatch = useAppDispatch();
    const reportsType = useAppSelector(selectReportsType);
    const typeaheadStatus = useAppSelector(selectTypeaheadStatus);
    let formOptions: any = useAppSelector((state) => selectMetadata(state, props.id));
    let selectedOptions: any = useAppSelector((state) => selectCurrentAudience(state, props.id));
    const handleSelect = useCallback((e: any) => {
        e.preventDefault();
        dispatch(setAudience({ id: props.id as keyof AudienceMetadata, value: e.detail.selectedOptions }))
    }, [dispatch, props.id])

    const manualFilterCheck = ['job_titles', 'reports'].includes(props.id);
    const handleFilterChange = useCallback((e: any) => {
        e.preventDefault();
        if (manualFilterCheck && props.id === 'job_titles') {
            dispatch(jobTitlesAsync(e.detail.filteringText));
        } else if (manualFilterCheck && props.id === 'reports') {
            dispatch(employeeSuggestionsAsync(e.detail.filteringText));
        }
    }, [dispatch, manualFilterCheck, props.id]);

    if (props.id === "reports") {
        formOptions = formOptions.map((option: any) => { return { iconSvg: <div className="image-div" style={{ backgroundImage: `url('https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${option.value}')` }}></div>, ...option } })
        selectedOptions = selectedOptions.map((option: any) => { return { iconSvg: <div className="image-div" style={{ backgroundImage: `url('https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${option.value}')` }}></div>, ...option } })
    }


    return (
        <div className={props.id === "reports" ? "audience-form-wrapper image-form-wrapper" : "audience-form-wrapper"}>
            {
                props.id === 'reports' ?
                    (
                        <RadioGroup
                            onChange={({ detail }) => dispatch(setReportsType(detail.value))}
                            value={reportsType}
                            items={[
                                { value: "direct", label: "Direct Reports" },
                                { value: "indirect", label: "Indirect Reports" }
                            ]}
                        />
                    ) :
                    (
                        <></>
                    )
            }
            <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
                <div>{props.label}</div>
                <div className="audience-form-field">
                    <Multiselect
                        filteringType={manualFilterCheck ? "manual" : "auto"}
                        selectedOptions={selectedOptions}
                        options={formOptions}
                        onChange={handleSelect}
                        onLoadItems={handleFilterChange}
                        placeholder={props.placeholder}
                        statusType={typeaheadStatus === "loading" ? "loading" : "finished"}
                        loadingText="Loading Options ..."
                        disabled={props.id === "regions"}
                    />
                </div>
            </Grid>
        </div>
    )
}

export default AudienceForm;