import { SpaceBetween } from "@amzn/awsui-components-react";
import { Link } from "react-router-dom";
import { STAGE_NAME } from "../../../features/apiConstants";

function TrainingReport() {
    const ALPHA_DASHBOARD_URL = "https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/764946308314/dashboards/6e6c9749-4feb-47ab-b4e4-bf5735cf77b2?directory_alias=amazonbi";
    const PROD_DASHBOARD_URL = "https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/764946308314/dashboards/752f68eb-9f7c-4345-b6ac-c47e5f2b30f1?directory_alias=amazonbi";
    return (
        // TODO: Iframe is not currently accessible 
        <div>
            <iframe
                width="100%"
                height="720"
                title="minerva report"
                src={STAGE_NAME === "minerva" ? PROD_DASHBOARD_URL : ALPHA_DASHBOARD_URL}>
            </iframe>
            {/* <SpaceBetween size="xl">
                &nbsp;
                <Link to={'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/6e6c9749-4feb-47ab-b4e4-bf5735cf77b2/sheets/6e6c9749-4feb-47ab-b4e4-bf5735cf77b2_e6665ae0-e93a-436c-b311-53fb463373e7'} target="_blank">Open QuickSight dashboard</Link>
            </SpaceBetween> */}
        </div>
    )
}

export default TrainingReport;