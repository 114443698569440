import { Box } from "@amzn/awsui-components-react";
import { TrainingDetail } from "../../../../../../features/fetchTraining";
import './fetchtrainingcard.css';

export interface FetchTrainingCardProps {
    training: TrainingDetail;
}

function FetchTrainingCard(props: FetchTrainingCardProps) {

    function secondsToHms(d: number | undefined, d2: number | undefined) {
        if (d !== undefined && d > 0) {
            d = Number(d);
        } else if (d2 !== undefined && d2 > 0) {
            d = Number(d2) * 60 * 60;
        } else {
            return "Unavailable from KNET";
        }
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);

        var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes ") : "";
        if (!mDisplay) {
            hDisplay = hDisplay.replace(",", "");
        }

        return hDisplay + mDisplay;
    }

    function formatDate(dateString: string | undefined) {
        if (dateString === undefined) {
            return "Unavailable from KNET"
        } else {
            return dateString.split("T")[0];
        }
    }

    return (
        <div className="training-search-card-info">
            <Box variant="awsui-key-label">{props.training.title}</Box>
            <Box variant="p" color="text-body-secondary"><div dangerouslySetInnerHTML={{ __html: props.training.description || props.training.description_html || 'Training Description Unavailable from KNET' }}></div></Box>
            <div className="training-search-card-metadata">
                <span className="metadata-field">
                    {/* TODO: Replace static values with API response */}
                    <Box variant="p" color="text-status-inactive">Last Updated : {formatDate(props.training.updated_on)}</Box>
                </span>
                <span className="metadata-field">
                    {/* TODO: Replace static values with API response */}
                    <Box variant="p" color="text-status-inactive">Duration : {secondsToHms(props.training.duration, props.training.duration_hours)}</Box>
                </span>
            </div>
        </div>
    )
}

export default FetchTrainingCard;