import { FormField, Multiselect } from "@amzn/awsui-components-react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectLibraryStatus, selectLibraryAudienceOptions, setLibraryAudience, selectLibraryCurrentAudience, libraryJobTitlesAsync, selectLibraryTypeaheadStatus } from '../../../../../features/library';
import './opsform.css';
import { AudienceFormOption, AudienceMetadata } from "../../../../../features/audience";
import { useCallback } from "react";

export interface LibraryFormProps {
    id: string;
    label: string;
    placeholder: string;
}

const regulatoryOptions: AudienceFormOption[] = [
    {
        label: "Yes",
        value: "true"
    },
    {
        label: "No",
        value: "false"
    }
]

function LibraryOpsField(props: LibraryFormProps) {

    const dispatch = useAppDispatch();

    const formValues: AudienceFormOption[] = useAppSelector(state => selectLibraryAudienceOptions(state, props.id));
    const loadingStatus = useAppSelector(selectLibraryStatus);
    const typeaheadStatus = useAppSelector(selectLibraryTypeaheadStatus);
    let formOptions: AudienceFormOption[];
    if (props.id === 'regulatory_requirement') {
        formOptions = regulatoryOptions;
    } else {
        formOptions = formValues;
    }


    const selectedOptions = useAppSelector(state => selectLibraryCurrentAudience(state, props.id));

    const handleFilterChange = useCallback((e: any) => {
        e.preventDefault();
        dispatch(libraryJobTitlesAsync(e.detail.filteringText));
    }, [dispatch]);

    let formLoadStatus;
    if (props.id === 'job_titles') {
        formLoadStatus = typeaheadStatus === "loading";
    } else {
        formLoadStatus = loadingStatus === "loading";
    }

    return (
        <div className="form-spacer">
            <FormField label={props.label}>
                <Multiselect
                    filteringType={props.id === 'job_titles' ? "manual" : "auto"}
                    onLoadItems={handleFilterChange}
                    selectedOptions={selectedOptions}
                    options={formOptions}
                    onChange={(e: any) => dispatch(setLibraryAudience({ id: props.id as keyof AudienceMetadata, value: e.detail.selectedOptions }))}
                    placeholder={props.placeholder}
                    statusType={formLoadStatus ? "loading" : "finished"}
                    loadingText="Loading Options ..."
                    tokenLimit={1}
                    disabled={props.id === 'regions'}
                />
            </FormField>
        </div>
    )
}

export default LibraryOpsField;