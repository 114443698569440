import { parse } from "iso8601-duration";

export function parseDuration(trainingDuration: string) {
    if (trainingDuration) {
        let isoDurationParsed = parse(trainingDuration);
        let durationString = "";
        if (isoDurationParsed.years) {
            durationString += durationString ? `, ${isoDurationParsed.years} year` : `${isoDurationParsed.years} year`;
        }
        if (isoDurationParsed.weeks) {
            durationString += durationString ? `, ${isoDurationParsed.weeks} week` : `${isoDurationParsed.weeks} week`;
        }
        if (isoDurationParsed.months) {
            durationString += durationString ? `, ${isoDurationParsed.months} month` : `${isoDurationParsed.months} month`;
        }
        if (isoDurationParsed.days) {
            durationString += durationString ? `, ${isoDurationParsed.days} day` : `${isoDurationParsed.days} day`;
        }
        if (isoDurationParsed.hours) {
            durationString += durationString ? `, ${isoDurationParsed.hours} hour` : `${isoDurationParsed.hours} hour`;
        }
        if (isoDurationParsed.minutes) {
            durationString += durationString ? `, ${isoDurationParsed.minutes} minute` : `${isoDurationParsed.minutes} minute`;
        }
        if (isoDurationParsed.seconds) {
            durationString += durationString ? `, ${isoDurationParsed.seconds} second` : `${isoDurationParsed.seconds} second`;
        }
        return durationString;

    }

    return null;
}

export function formatDate(dateString: string) {
    const localDate = new Date(dateString);
    return ('0' + (localDate.getMonth() + 1)).slice(-2) + '/' + ('0' + localDate.getDate()).slice(-2) + '/' + localDate.getFullYear();
}