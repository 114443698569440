import { RootState } from "../app/store";

export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.log("Error in load state", err);
        return undefined;
    }
};

export const saveState = (state: RootState) => {
    try {
        const stateObj = {
            audience: state.audience,
            fetchTraining: state.fetchTraining,
            trainingConfiguration: state.trainingConfiguration,
            trainingDetails: state.trainingDetails,
            schedule: state.schedule,
            auth: {
                username: 'johndoe',
                admin: false,
                status: 'idle',
                isLoggedIn: 'no',
                redirectURI: state.auth.redirectURI
            }
        }
        const serializedState = JSON.stringify(stateObj);
        sessionStorage.setItem('state', serializedState);
    } catch (err) {
        // ignore write errors
        console.log("Unable to save redux state", err);
    }
};