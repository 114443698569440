import { createBrowserRouter } from "react-router-dom";
import Landing from "../components/landing";
import AppBase from "../components/appbase";
import ErrorHandler from "../components/errorhandler";
import TrainingReport from "../components/trainingreport";
import TrainingConfiguration from "../components/trainingconfiguration";
import TrainingLibrary from "../components/traininglibrary";
import FetchTrainingForm from "../components/trainingconfiguration/forms/fetchtraining";
import VerifyTrainingForm from "../components/trainingconfiguration/forms/verifytraining";
import TrainingDetailsForm from "../components/trainingconfiguration/forms/trainingdetails";
import TrainingAudienceForm from "../components/trainingconfiguration/forms/trainingaudience";
import TrainingScheduleForm from "../components/trainingconfiguration/forms/trainingschedule";
import { BreadcrumbGroupProps } from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import TrainingSummary from "../components/trainingsummary";
import TrainingLibraryContainer from "../components/traininglibrarycontainer";
import AuthGuard from "../components/AuthGuard";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <AppBase />,
        errorElement: <ErrorHandler />,
        children: [
            {
                path: "",
                element: <Landing />
            },
            {
                path: "trainingreport",
                element: <TrainingReport />
            },
            {
                path: "trainingconfiguration",
                element: <AuthGuard outlet={<TrainingConfiguration />}></AuthGuard>,
                children: [
                    {
                        path: "",
                        element: <FetchTrainingForm />
                    },
                    {
                        path: "fetchtraining",
                        element: <FetchTrainingForm />
                    },
                    {
                        path: "verifytraining",
                        element: <VerifyTrainingForm />
                    },
                    {
                        path: "trainingdetails",
                        element: <TrainingDetailsForm />
                    },
                    {
                        path: "trainingaudience",
                        element: <TrainingAudienceForm />
                    },
                    {
                        path: "trainingschedule",
                        element: <TrainingScheduleForm />
                    }
                ]
            },
            {
                path: "traininglibrary",
                element: <TrainingLibraryContainer />,
                children: [
                    {
                        path: "",
                        element: <TrainingLibrary />
                    },
                    {
                        path: "trainingsummary/:training_id",
                        element: <TrainingSummary />
                    }
                ]
            }
        ]
    }
]);


//Breadcrum map is added here because it must be updated together with routes each time
export const crumbMap: { [key: string]: BreadcrumbGroupProps.Item } = {
    '': {
        text: 'Home',
        href: '/'
    },
    'trainingreport': {
        text: 'Training Report',
        href: '/trainingreport'
    },
    'traininglibrary': {
        text: 'Training Library',
        href: '/traininglibrary'
    },
    'trainingconfiguration': {
        text: 'Map Training',
        href: '/trainingconfiguration/fetchtraining'
    },
    'fetchtraining': {
        text: 'Fetch Training',
        href: '/trainingconfiguration/fetchtraining'
    },
    'verifytraining': {
        text: 'Verify Training',
        href: '/trainingconfiguration/verifytraining'
    },
    'trainingdetails': {
        text: 'Training Details',
        href: '/trainingconfiguration/trainingdetails'
    },
    'trainingaudience': {
        text: 'Training Audience',
        href: 'trainingconfiguration/trainingaudience'
    },
    'trainingschedule': {
        text: 'Training Schedule',
        href: 'trainingconfiguration/trainingschedule'
    },
    'trainingsummary': {
        text: 'Training Summary',
        href: 'traininglibrary'
    }
};