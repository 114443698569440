import { FormField, Multiselect } from "@amzn/awsui-components-react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { TrainingDetailFormOption } from "../../../../../features/trainingDetails";
import { selectLibraryStatus, selectLibraryTrainingOptions, selectCurrentLibraryOption, setLibraryTrainingOption } from '../../../../../features/library';
import './libraryform.css';

export interface LibraryFormProps {
    id: string;
    label: string;
    placeholder: string;
}

const regulatoryOptions: TrainingDetailFormOption[] = [
    {
        label: "Yes",
        value: "true"
    },
    {
        label: "No",
        value: "false"
    }
]

function LibraryFormField(props: LibraryFormProps) {

    const dispatch = useAppDispatch();

    const formValues: string[] = useAppSelector(state => selectLibraryTrainingOptions(state, props.id));
    const loadingStatus = useAppSelector(selectLibraryStatus);
    let formOptions: TrainingDetailFormOption[];
    if (props.id === 'regulatory_requirement') {
        formOptions = regulatoryOptions;
    } else if (formValues && formValues.length > 0) {
        formOptions = formValues.map(value => {
            return {
                label: value,
                value
            }
        })
    } else {
        formOptions = [];
    }


    const selectedOptions = useAppSelector(state => selectCurrentLibraryOption(state, props.id));

    return (
        <div className="form-spacer">
            <FormField label={props.label}>
                <Multiselect
                    filteringType="auto"
                    selectedOptions={selectedOptions}
                    options={formOptions}
                    onChange={(e: any) => dispatch(setLibraryTrainingOption({ id: props.id, value: e.detail.selectedOptions }))}
                    placeholder={props.placeholder}
                    statusType={loadingStatus === "loading" ? "loading" : "finished"}
                    loadingText="Loading Options ..."
                    tokenLimit={1}
                />
            </FormField>
        </div>
    )
}

export default LibraryFormField;