import { Box } from "@amzn/awsui-components-react";
import './operationalaudience.css';
import AudienceForm, { AudienceFormProps } from "../audienceform";
import { useAppSelector } from "../../../../../../hooks";
import { selectAudienceStatus } from "../../../../../../../features/audience";
import LoadSpinner from "../../../../../LoadSpinner";

const opsPrimaryFormFields: AudienceFormProps[] = [
    {
        id: 'org',
        label: 'Org',
        placeholder: 'Choose Org'
    },
    {
        id: 'suborgs',
        label: 'Sub Org',
        placeholder: 'Choose Sub Org'
    },
    {
        id: 'site_types',
        label: 'Site Type',
        placeholder: 'Choose Site Type'
    },
    {
        id: 'regions',
        label: 'Region',
        placeholder: 'Choose Region'
    },
    {
        id: 'countries',
        label: 'Country',
        placeholder: 'Choose Country'
    },
    {
        id: 'sites',
        label: 'Site',
        placeholder: 'Choose Site'
    },
];

const opsSecondaryFormFields: AudienceFormProps[] = [
    {
        id: 'process_paths',
        label: 'Process Path',
        placeholder: 'Choose Process Path'
    },
    {
        id: 'job_titles',
        label: 'Job Title',
        placeholder: 'Choose Job Families'
    },
    {
        id: 'job_level',
        label: 'Job Level',
        placeholder: 'Choose Job Level'
    },
    {
        id: 'amazon_tenure',
        label: 'Amazon Tenure',
        placeholder: 'Choose Tenure'
    },
    {
        id: 'employment_status',
        label: 'Employment Status',
        placeholder: 'Choose Employment Status'
    },
    {
        id: 'reports',
        label: 'Reports Of',
        placeholder: '@'
    }
];

function OperationalAudience() {

    const audienceStatus = useAppSelector(selectAudienceStatus);

    return (
        <div>
            {
                audienceStatus === 'loading' ?
                    (
                        <LoadSpinner />
                    ) :
                    (
                        <div>
                            {
                                opsPrimaryFormFields.map((formInfo, index) =>
                                (
                                    <AudienceForm key={index} id={formInfo.id} label={formInfo.label} placeholder={formInfo.placeholder} />
                                ))
                            }
                            <div className="ops-additional-fields">
                                <Box variant="awsui-key-label">Additional Criteria</Box>
                            </div>
                            {
                                opsSecondaryFormFields.map((formInfo, index) =>
                                (
                                    <AudienceForm key={index} id={formInfo.id} label={formInfo.label} placeholder={formInfo.placeholder} />
                                ))
                            }

                        </div>
                    )
            }


        </div>
    )
}

export default OperationalAudience;