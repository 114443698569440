import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { deleteTraining, fetchSummary } from "./summaryAPI";

export interface SummaryState {
    trainingConfigInfo: any;
    status: 'idle' | 'loading' | 'failed';
}

export interface DeleteTrainingInfo {
    trainingId: string;
    version: number;
}

const initialState: SummaryState = {
    trainingConfigInfo: null,
    status: 'idle'
}

export const selectSummary = (state: RootState) => state.summary.trainingConfigInfo;

export const fetchSummaryAsync = createAsyncThunk(
    'summary/fetchSummaryAsync',
    async (trainingId: string) => {
        if (trainingId !== "") {
            const response = await fetchSummary(trainingId);
            return response;
        } else {
            return {};
        }
    }
);

export const deleteTrainingAsync = createAsyncThunk(
    'summary/deleteTrainingAsync',
    async (trainingInfo: DeleteTrainingInfo) => {
        const response = await deleteTraining(trainingInfo);
        return response;
    }
);

export const selectSummaryStatus = (state: RootState) => state.summary.status;

export const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {
        resetSummary: () => initialState,
        setSummaryStatus: (state, action: PayloadAction<any>) => {
            state.status = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSummaryAsync.pending, (state) => {
                state.status = 'loading';
                state.trainingConfigInfo = null;
            })
            .addCase(fetchSummaryAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.trainingConfigInfo = action.payload;
            })
            .addCase(fetchSummaryAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(deleteTrainingAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteTrainingAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.trainingConfigInfo = action.payload;
            })
            .addCase(deleteTrainingAsync.rejected, (state) => {
                state.status = 'failed';
            })
    },
});

export const { resetSummary, setSummaryStatus } = summarySlice.actions;

export default summarySlice.reducer;