import { Container, DatePicker, FormField, Grid, Header, Input, RadioGroup, Select } from "@amzn/awsui-components-react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectDueDate, selectDueDateType, selectDueDateValue, selectRecurrenceType, selectRecurrenceValue, selectScheduleType, setDueDate, setDueDateType, setDueDateValue, setRecurenceValue, setRecurrenceType, setScheduleType } from "../../../../../features/schedule";
import './trainingschedule.css';
import { selectValidationStatus } from "../../../../../features/trainingConfiguration";
// import { formatDate } from "../../../trainingsummary";

const scheduleOptions = [
    { label: "day", value: "day" },
    { label: "week", value: "week" },
    { label: "month", value: "month" },
    { label: "quarter", value: "quarter" },
    { label: "year", value: "year" }
];

function TrainingScheduleForm() {
    const dispatch = useAppDispatch();
    const schedule_type = useAppSelector(selectScheduleType);
    const dueDate = useAppSelector(selectDueDate);
    const dueDateType = useAppSelector(selectDueDateType);
    const dueDateValue = useAppSelector(selectDueDateValue);
    const recurrenceType = useAppSelector(selectRecurrenceType);
    const recurrenceValue = useAppSelector(selectRecurrenceValue);
    const validationStatus = useAppSelector(selectValidationStatus);

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description="Setup one time or recurring schedule here"
                >
                    Training Schedule
                </Header>
            }
            className="configuration-container"
        >
            <RadioGroup
                onChange={({ detail }) => dispatch(setScheduleType(detail.value))}
                value={schedule_type}
                items={[
                    { value: "ONCE", label: "One Time Training" },
                    { value: "RECURRENCE", label: "Recurring Training" }
                ]}
            />

            <FormField
                label="Due Date"
                description="Use YYYY/MM/DD format."
                errorText={schedule_type === "ONCE" && validationStatus ? "Please select a valid date for One Time Training" : ""}
            >
                {/* <div className="datepicker-overlay">{dueDate.length === 10 ? formatDate(dueDate) : ""}</div> */}
                <DatePicker
                    onChange={({ detail }) => dispatch(setDueDate(detail.value))}
                    value={dueDate}
                    openCalendarAriaLabel={selectedDate =>
                        "Choose training due date" +
                        (selectedDate
                            ? `, selected date is ${selectedDate}`
                            : "")
                    }
                    nextMonthAriaLabel="Next month"
                    placeholder="MM/DD/YYYY"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                    isDateEnabled={date => {
                        let localDate = new Date();
                        localDate.setDate(localDate.getDate() + 7);
                        return date > localDate;
                    }
                    }
                    disabled={schedule_type === "RECURRENCE"}
                />
            </FormField>
            &nbsp;
            <Grid
                gridDefinition={[{ colspan: 5 }, { colspan: 2 }, { colspan: 5 }]}
            >
                <FormField
                    label={
                        <span>
                            Frequency <i>( * Required )</i>
                        </span>
                    }
                    description="Repeat after every selected interval below"
                    errorText={schedule_type === "RECURRENCE" && (!recurrenceValue || !recurrenceType) && validationStatus ? "Enter Frequency greater than Due Date Time Period" : ""}
                >
                    <div className="schedule-form-wrapper">
                        <Input
                            placeholder="Select Period"
                            onChange={(e: any) => dispatch(setRecurenceValue(e.detail.value))}
                            value={recurrenceValue}
                            inputMode="numeric"
                            type="number"
                            disabled={schedule_type === "ONCE"}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Select
                            placeholder="Select Duration"
                            selectedOption={recurrenceType}
                            onChange={(e: any) =>
                                dispatch(setRecurrenceType(e.detail.selectedOption))
                            }
                            options={scheduleOptions}
                            disabled={schedule_type === "ONCE"}
                        />
                    </div>
                </FormField>

                <div>&nbsp;</div>

                <FormField
                    label={
                        <span>
                            Due Date Time Period <i>( * Required )</i>
                        </span>
                    }
                    description="How much time will learner have to complete this training"
                    errorText={schedule_type === "RECURRENCE" && validationStatus ? "Enter Due Date Period less than frequency" : ""}
                >
                    <div className="schedule-form-wrapper">
                        <Input
                            placeholder="Select Period"
                            onChange={(e: any) => dispatch(setDueDateValue(e.detail.value))}
                            value={dueDateValue}
                            inputMode="numeric"
                            type="number"
                            disabled={schedule_type === "ONCE"}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Select
                            placeholder="Select Duration"
                            selectedOption={dueDateType}
                            onChange={(e: any) =>
                                dispatch(setDueDateType(e.detail.selectedOption))
                            }
                            options={scheduleOptions}
                            disabled={schedule_type === "ONCE"}
                        />
                    </div>
                </FormField>
            </Grid>

        </Container>
    )
}

export default TrainingScheduleForm;