import { selectAudienceStatus } from "../../../../../../../features/audience";
import { useAppSelector } from "../../../../../../hooks";
import LoadSpinner from "../../../../../LoadSpinner";
import AudienceForm, { AudienceFormProps } from "../audienceform";

const corpFormFields: AudienceFormProps[] = [
    {
        id: 'regions',
        label: 'Region',
        placeholder: 'Select Region'
    },
    {
        id: 'countries',
        label: 'Country',
        placeholder: 'Choose Country'
    },
    {
        id: 'job_titles',
        label: 'Job Titles',
        placeholder: 'Choose Job Titles'
    },
    {
        id: 'job_level',
        label: 'Job Level',
        placeholder: 'Choose Job Level'
    },
    {
        id: 'amazon_tenure',
        label: 'Amazon Tenure',
        placeholder: 'Choose Tenure'
    },
    {
        id: 'employment_status',
        label: 'Employment Status',
        placeholder: 'Choose Employment Status'
    },
    {
        id: 'reports',
        label: 'Reports Of',
        placeholder: '@'
    }
];

function CorpAudience() {

    const audienceStatus = useAppSelector(selectAudienceStatus);

    return (
        <div>
            {audienceStatus === 'loading' ?
                (
                    <LoadSpinner />
                ) :
                (
                    corpFormFields.map((formInfo, index) =>
                    (
                        <AudienceForm key={index} id={formInfo.id} label={formInfo.label} placeholder={formInfo.placeholder} />
                    ))
                )
            }
        </div>
    )
}

export default CorpAudience;