import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import './libraryfilters.css';
import LibraryFormField, { LibraryFormProps } from './libraryform';
import { loadAudienceMetatdataAsync, loadLibraryTrainingMetadataAsync, selectLibraryStatus, selectLibraryTrainingOptions } from '../../../../features/library';
import { AudienceFormProps } from '../../trainingconfiguration/forms/trainingaudience/audiencepopup/audienceform';
import LibraryOpsField from './opsform';

export const trainingDetailsFormFields: LibraryFormProps[] = [
    {
        id: 'training_category',
        label: 'Training Category',
        placeholder: 'Select Training Category'
    },
    {
        id: 'ms_level',
        label: 'WHS Management System',
        placeholder: 'Select WHS Management System'
    },
    {
        id: 'training_program',
        label: 'Training Program',
        placeholder: 'Select Training Program'
    },
    {
        id: 'training_type',
        label: 'Training Type',
        placeholder: 'Select Training Type'
    },
    {
        id: 'training_modality',
        label: 'Training Mode',
        placeholder: 'Select Training Mode'
    },
    {
        id: 'regulatory_requirement',
        label: 'Regulatory Status',
        placeholder: 'Select Regulatory Status'
    }
];

const audienceFormFields: AudienceFormProps[] = [
    {
        id: 'org',
        label: 'Org',
        placeholder: 'Select Org'
    },
    {
        id: 'suborgs',
        label: 'Sub Org',
        placeholder: 'Select Sub Org'
    },
    {
        id: 'regions',
        label: 'Region',
        placeholder: 'Select Region'
    },
    {
        id: 'countries',
        label: 'Country',
        placeholder: 'Select Country'
    },
    {
        id: 'site_types',
        label: 'Site Type',
        placeholder: 'Select Site Type'
    },
    {
        id: 'sites',
        label: 'Site',
        placeholder: 'Select Site'
    },
    // TODO - Remove these 2 filters after confirmation
    // {
    //     id: 'job_titles',
    //     label: 'Job Title',
    //     placeholder: 'Select Job Families'
    // },
    // {
    //     id: 'job_level',
    //     label: 'Job Level',
    //     placeholder: 'Select Job Level'
    // }
];

function LibraryFilters() {

    const dispatch = useAppDispatch();
    const optionCheck = useAppSelector(state => selectLibraryTrainingOptions(state, 'training_program'));
    const libraryStatus = useAppSelector(selectLibraryStatus);

    useEffect(() => {
        if (optionCheck.length === 0 && libraryStatus !== 'loading') {
            dispatch(loadLibraryTrainingMetadataAsync());
            dispatch(loadAudienceMetatdataAsync());
        }
    });

    return (
        <div className="library-filter-container">
            <div className="library-filter-content">
                {
                    trainingDetailsFormFields.map((formInfo, index) =>
                    (
                        <LibraryFormField id={formInfo.id} label={formInfo.label} placeholder={formInfo.placeholder} key={index} />
                    ))
                }
            </div>
            <div className="library-filter-content">
                {
                    audienceFormFields.map((formInfo, index) => (
                        <LibraryOpsField id={formInfo.id} label={formInfo.label} placeholder={formInfo.placeholder} key={index} />
                    ))
                }
            </div>
        </div>
    )
}

export default LibraryFilters;