import { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface NotificationState {
    notificationList: FlashbarProps.MessageDefinition[];
    status: 'idle' | 'loading' | 'failed';
}

const initialState: NotificationState = {
    notificationList: [],
    status: 'idle'
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        sendNotification: (state, action: PayloadAction<FlashbarProps.MessageDefinition>) => {
            state.notificationList.unshift(action.payload);
        },
        removeNotification: (state, action: PayloadAction<number>) => {
            state.notificationList = state.notificationList.filter((notification, index) => index !== action.payload);
        },
        clearAllNotification: () => initialState
    }
});

export const { sendNotification, removeNotification, clearAllNotification } = notificationSlice.actions;

export const selectNotification = (state: RootState) => state.notifications.notificationList;

export default notificationSlice.reducer;