import { Box } from "@amzn/awsui-components-react";

interface DeletePopupProps {
    training_name: string;
}

function DeletePopup(props: DeletePopupProps) {
    return (
        <Box fontSize="heading-s">
            <p>Are you sure you want to delete this training ?</p>
            <b>{props.training_name}</b>
        </Box>
    )
}

export default DeletePopup;