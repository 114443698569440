import { PayloadAction, createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { fetchAudienceAttribute, fetchSuggestionsEmployee, fetchSuggestionsJobTitle } from "./audienceAPI";
import { RootState } from "../../app/store";

export interface ProcessPath {
    id: string;
    value: string;
}

export interface JobTitle {
    profile_name: string;
    profile_id: string;
    profile_level?: string;
}

export interface AmazonTenure {
    id: string;
    value: string;
}

export interface EmploymentStatus {
    id: string;
    value: string;
}

export interface Reports {
    direct?: string[];
    indirect?: string[];
}

export interface SiteDetail {
    colocated?: boolean;
    costCenters?: string[];
    country: string;
    org: string;
    parentSite: string;
    region: string;
    site: string;
    siteType: string;
    suborg: string;
}

export interface AudienceDetail {
    audience_id?: string;
    audience_type: string;
    orgs: string[];
    suborg: string[];
    regions: string[];
    countries: string[];
    site_types: string[];
    sites: string[];
    process_paths: ProcessPath[];
    job_titles: JobTitle[];
    job_levels: string[];
    amazon_tenure: AmazonTenure[];
    employment_status: EmploymentStatus[];
    reports: Reports;
    employee_count?: string;
    updated_on?: string;
}

export interface AudienceAttributes {
    amazon_tenure: AmazonTenure[];
    employment_status: EmploymentStatus[];
    job_levels: string[];
    process_path: ProcessPath[];
    regions: string[];
    sites: SiteDetail[];
}

export interface AudienceSelectedValues {
    org: string[];
}

export interface AudienceFormOption {
    label: string;
    value: string;
}

export interface AudienceFormPayload {
    id: keyof AudienceMetadata;
    value: AudienceFormOption[];

}

export interface AudienceMetadata {
    org: AudienceFormOption[];
    suborgs: AudienceFormOption[];
    regions: AudienceFormOption[];
    countries: AudienceFormOption[];
    site_types: AudienceFormOption[];
    sites: AudienceFormOption[];
    process_paths: AudienceFormOption[];
    job_titles: AudienceFormOption[];
    job_level: AudienceFormOption[];
    amazon_tenure: AudienceFormOption[];
    employment_status: AudienceFormOption[];
    reports: AudienceFormOption[];
}

export interface AudienceDetailState {
    current_audience: AudienceDetail;
    audience_list: AudienceDetail[];
    status: 'idle' | 'loading' | 'failed';
    typeaheadStatus: 'idle' | 'loading' | 'failed';
    audience_attributes: AudienceAttributes;
    selectedOptions: AudienceMetadata;
    metadata: AudienceMetadata;
    reportsType: string;
    audienceVisible: boolean;
    currentAudienceIndex: number;
    opsFilters: AudienceAttributes | null;
    corpFilters: AudienceAttributes | null;
}

const initialState: AudienceDetailState = {
    current_audience: {
        audience_type: "Operations",
        orgs: [],
        suborg: [],
        regions: [],
        countries: [],
        site_types: [],
        sites: [],
        process_paths: [],
        job_titles: [],
        job_levels: [],
        amazon_tenure: [],
        employment_status: [],
        reports: {}
    },
    audience_attributes: {
        amazon_tenure: [],
        employment_status: [],
        job_levels: [],
        process_path: [],
        regions: [],
        sites: []
    },
    audience_list: [],
    status: 'idle',
    typeaheadStatus: 'idle',
    selectedOptions: {
        org: [],
        suborgs: [],
        regions: [{ label: 'NA', value: 'NA' }],
        countries: [],
        site_types: [],
        sites: [],
        process_paths: [],
        job_titles: [],
        job_level: [],
        amazon_tenure: [],
        employment_status: [],
        reports: [],
    },
    metadata: {
        org: [],
        suborgs: [],
        regions: [],
        countries: [],
        site_types: [],
        sites: [],
        process_paths: [],
        job_titles: [],
        job_level: [],
        amazon_tenure: [],
        employment_status: [],
        reports: [],
    },
    reportsType: 'direct',
    audienceVisible: false,
    currentAudienceIndex: -1,
    opsFilters: null,
    corpFilters: null
}

export const selectAudience = (state: RootState) => state.audience;
export const selectAudienceVisible = (state: RootState) => state.audience.audienceVisible;
export const selectAudienceStatus = (state: RootState) => state.audience.status;
export const selectAudienceType = (state: RootState) => state.audience.current_audience?.audience_type;
export const selectAudienceId = (state: RootState) => state.audience.current_audience?.audience_id || "";
export const selectMetadata = (state: RootState, id: string) => state.audience.metadata[id as keyof AudienceMetadata];
export const selectCurrentAudience = (state: RootState, id: string) => state.audience.selectedOptions[id as keyof AudienceMetadata];
export const selectAllOptionValues = (state: RootState) => state.audience.selectedOptions;
export const selectReportsType = (state: RootState) => state.audience.reportsType;
export const selectTypeaheadStatus = (state: RootState) => state.audience.typeaheadStatus;
export const selectAudienceList = (state: RootState) => state.audience.audience_list;

export const audienceAttributesAsync = createAsyncThunk(
    'audience/audienceAttributesAsync',
    async (audience_type: string, { getState }) => {
        const audienceState = (getState() as RootState).audience;
        if (audience_type === "Operations" && audienceState.opsFilters) {
            return {
                audience_type,
                response: audienceState.opsFilters
            };
        } else if (audience_type === "WHS" && audienceState.corpFilters) {
            return {
                audience_type,
                response: audienceState.corpFilters
            };
        } else {
            const response = await fetchAudienceAttribute(audience_type);
            return {
                audience_type: audience_type,
                response: response
            }
        }
    }
);

export const jobTitlesAsync = createAsyncThunk(
    'audience/jobTitlesAsync',
    async (filterText: string) => {
        const response = await fetchSuggestionsJobTitle(filterText);
        return response;
    }
);

export const employeeSuggestionsAsync = createAsyncThunk(
    'audience/employeeSuggestionsAsync',
    async (filterText: string) => {
        const response = await fetchSuggestionsEmployee(filterText);
        return response;
    }
);

export const audienceSlice = createSlice({
    name: 'audience',
    initialState,
    reducers: {
        setAudienceVisible: (state, action: PayloadAction<boolean>) => {
            state.audienceVisible = action.payload;
        },
        editAudience: (state, action: PayloadAction<number>) => {
            const currentAudience = current(state.audience_list[action.payload]);
            state.currentAudienceIndex = action.payload;
            state.current_audience = currentAudience;

            if (currentAudience.sites) {
                state.selectedOptions.sites = currentAudience.sites.map((value) => { return { label: value, value } });
            }
            if (currentAudience.orgs) {
                state.selectedOptions.org = currentAudience.orgs.map((value) => { return { label: value, value } });
            }
            if (currentAudience.suborg) {
                state.selectedOptions.suborgs = currentAudience.suborg.map((value) => { return { label: value, value } });
            }
            if (currentAudience.regions) {
                state.selectedOptions.regions = currentAudience.regions.map((value) => { return { label: value, value } });
            }
            if (currentAudience.countries) {
                state.selectedOptions.countries = currentAudience.countries.map((value) => { return { label: value, value } });
            }
            if (currentAudience.site_types) {
                state.selectedOptions.site_types = currentAudience.site_types.map((value) => { return { label: value, value } });
            }
            if (currentAudience.process_paths) {
                state.selectedOptions.process_paths = currentAudience.process_paths.map((pp) => {
                    return {
                        label: pp.value,
                        value: JSON.stringify(pp)
                    }
                })
            }
            if (currentAudience.job_levels) {
                state.selectedOptions.job_level = currentAudience.job_levels.map((value) => { return { label: value, value } });
            }
            if (currentAudience.amazon_tenure) {
                state.selectedOptions.amazon_tenure = currentAudience.amazon_tenure.map((tenure) => {
                    return {
                        label: tenure.value,
                        value: JSON.stringify(tenure)
                    }
                })
            }
            if (currentAudience.employment_status) {
                state.selectedOptions.employment_status = currentAudience.employment_status.map((status) => {
                    return {
                        label: status.value,
                        value: JSON.stringify(status)
                    }
                })
            }
            if (currentAudience.job_titles) {
                state.selectedOptions.job_titles = currentAudience.job_titles.map((record: any) => {
                    const profileInfo = {
                        profile_name: record.profile_name,
                        profile_id: record.profile_id,
                        profile_level: record.profile_level
                    }

                    return {
                        label: profileInfo.profile_name,
                        value: JSON.stringify(profileInfo)
                    }
                })
            }

            if (currentAudience.reports) {
                if (currentAudience.reports.direct) {
                    state.selectedOptions.reports = currentAudience.reports.direct.map((value) => { return { label: value, value } });
                    state.reportsType = 'direct';
                }
                if (currentAudience.reports.indirect) {
                    state.selectedOptions.reports = currentAudience.reports.indirect.map((value) => { return { label: value, value } });
                    state.reportsType = 'indirect';
                }
            }

            if (currentAudience.audience_type === "Operations") {
                const currentSelectedOptions = current(state.selectedOptions);
                const orgFilters = currentSelectedOptions.org.map((formInfo) => formInfo.value);
                const suborgFilters = currentSelectedOptions.suborgs.map((formInfo) => formInfo.value);
                const regionFilters = currentSelectedOptions.regions.map((formInfo) => formInfo.value);
                const countryFilters = currentSelectedOptions.countries.map((formInfo) => formInfo.value);
                const siteTypeFilters = currentSelectedOptions.site_types.map((formInfo) => formInfo.value);
                const siteFilters = currentSelectedOptions.sites.map((formInfo) => formInfo.value);

                const audienceAttributes = current(state.audience_attributes);


                const siteList: string[] = [];
                const orgList = new Set<string>();
                const suborgList = new Set<string>();
                const regionList = new Set<string>();
                const countryList = new Set<string>();
                const siteTypeList = new Set<string>();


                audienceAttributes.sites.forEach((siteDetail: SiteDetail) => {

                    // To filter any field we'll check all other fields except the current field
                    const orgCondition = (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const suborgCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const siteTypeCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const regionCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const countryCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const siteCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType));

                    if (orgCondition) {
                        orgList.add(siteDetail.org);
                    }
                    if (suborgCondition) {
                        suborgList.add(siteDetail.suborg);
                    }
                    if (siteTypeCondition) {
                        siteTypeList.add(siteDetail.siteType);
                    }
                    if (regionCondition) {
                        regionList.add(siteDetail.region);
                    }
                    if (countryCondition) {
                        countryList.add(siteDetail.country);
                    }
                    if (siteCondition) {
                        siteList.push(siteDetail.site);
                    }
                });

                state.metadata.sites = siteList.sort().map((value) => { return { label: value, value } });
                state.metadata.countries = Array.from(countryList).map((value) => { return { label: value, value } });
                state.metadata.regions = Array.from(regionList).map((value) => { return { label: value, value } });
                state.metadata.site_types = Array.from(siteTypeList).map((value) => { return { label: value, value } });
                state.metadata.suborgs = Array.from(suborgList).map((value) => { return { label: value, value } });
                state.metadata.org = Array.from(orgList).map((value) => { return { label: value, value } });
            }


        },
        deleteAudience: (state, action: PayloadAction<number>) => {
            const currentAudienceList = [...current(state.audience_list)];
            currentAudienceList.splice(action.payload, 1)
            state.audience_list = currentAudienceList;
        },
        setAudience: (state, action: PayloadAction<AudienceFormPayload>) => {

            state.selectedOptions[action.payload.id] = action.payload.value;

            if (state.current_audience?.audience_type === "Operations") {
                const currentSelectedOptions = current(state.selectedOptions);
                const orgFilters = currentSelectedOptions.org.map((formInfo) => formInfo.value);
                const suborgFilters = currentSelectedOptions.suborgs.map((formInfo) => formInfo.value);
                const regionFilters = currentSelectedOptions.regions.map((formInfo) => formInfo.value);
                const countryFilters = currentSelectedOptions.countries.map((formInfo) => formInfo.value);
                const siteTypeFilters = currentSelectedOptions.site_types.map((formInfo) => formInfo.value);
                const siteFilters = currentSelectedOptions.sites.map((formInfo) => formInfo.value);

                const audienceAttributes = current(state.audience_attributes);


                const siteList: string[] = [];
                const orgList = new Set<string>();
                const suborgList = new Set<string>();
                const regionList = new Set<string>();
                const countryList = new Set<string>();
                const siteTypeList = new Set<string>();


                audienceAttributes.sites.forEach((siteDetail: SiteDetail) => {

                    // To filter any field we'll check all other fields except the current field
                    const orgCondition = (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const suborgCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const siteTypeCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const regionCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const countryCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                        (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                    const siteCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                        (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                        (regionFilters.length === 0 || regionFilters.includes(siteDetail.region)) &&
                        (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                        (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType));

                    if (orgCondition) {
                        orgList.add(siteDetail.org);
                    }
                    if (suborgCondition) {
                        suborgList.add(siteDetail.suborg);
                    }
                    if (siteTypeCondition) {
                        siteTypeList.add(siteDetail.siteType);
                    }
                    if (regionCondition) {
                        regionList.add(siteDetail.region);
                    }
                    if (countryCondition) {
                        countryList.add(siteDetail.country);
                    }
                    if (siteCondition) {
                        siteList.push(siteDetail.site);
                    }
                });

                state.metadata.sites = siteList.sort().map((value) => { return { label: value, value } });
                state.metadata.countries = Array.from(countryList).map((value) => { return { label: value, value } });
                state.metadata.regions = Array.from(regionList).map((value) => { return { label: value, value } });
                state.metadata.site_types = Array.from(siteTypeList).map((value) => { return { label: value, value } });
                state.metadata.suborgs = Array.from(suborgList).map((value) => { return { label: value, value } });
                state.metadata.org = Array.from(orgList).map((value) => { return { label: value, value } });
            }
        },
        setReportsType: (state, action: PayloadAction<string>) => {
            state.reportsType = action.payload;
        },
        addAudience: (state, action: PayloadAction<AudienceDetail>) => {
            const currentAudienceIndex = current(state).currentAudienceIndex;
            if (currentAudienceIndex === -1) {
                state.audience_list.push(action.payload);
            } else {
                state.audience_list[currentAudienceIndex] = action.payload;
            }

            state.selectedOptions = initialState.selectedOptions;
        },
        setAudienceList: (state, action: PayloadAction<AudienceDetail[]>) => {
            if (action.payload) {
                state.audience_list = action.payload;
            }
        },
        resetCurrentAudience: (state) => {
            state.current_audience = initialState.current_audience;
            state.selectedOptions = initialState.selectedOptions;
            state.currentAudienceIndex = initialState.currentAudienceIndex;
            state.reportsType = initialState.reportsType;
        },
        resetAudience: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(audienceAttributesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(audienceAttributesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload.audience_type === "Operations") {
                    state.opsFilters = action.payload.response;
                } else if (action.payload.audience_type === "WHS") {
                    state.corpFilters = action.payload.response;
                }
                state.current_audience = {
                    audience_type: action.payload.audience_type,
                    orgs: [],
                    suborg: [],
                    regions: [],
                    countries: [],
                    site_types: [],
                    sites: [],
                    process_paths: [],
                    job_titles: [],
                    job_levels: [],
                    amazon_tenure: [],
                    employment_status: [],
                    reports: {}
                }
                state.selectedOptions = initialState.selectedOptions;

                //TODO - IMPLEMENT FILTERING OF ORG/SUBORG HIERARCHY
                const responseAttribute: AudienceAttributes = action.payload.response
                state.audience_attributes = responseAttribute;

                const siteList: string[] = [];
                const orgList = new Set<string>();
                const suborgList = new Set<string>();
                const regionList = new Set<string>();
                const countryList = new Set<string>();
                const siteTypeList = new Set<string>();

                (state.audience_attributes.sites || []).forEach((siteDetail: SiteDetail) => {
                    siteList.push(siteDetail.site);
                    orgList.add(siteDetail.org);
                    suborgList.add(siteDetail.suborg);
                    regionList.add(siteDetail.region);
                    countryList.add(siteDetail.country);
                    siteTypeList.add(siteDetail.siteType);
                })

                state.metadata.sites = siteList.sort().map((value) => { return { label: value, value } });
                state.metadata.org = Array.from(orgList).sort().map((value) => { return { label: value, value } });
                state.metadata.suborgs = Array.from(suborgList).sort().map((value) => { return { label: value, value } });

                if (action.payload.audience_type === "WHS") {
                    (action.payload.response.regions || []).forEach((region: any) => {
                        regionList.add(region.id);
                        region.countryCodes.forEach((country: string) => {
                            countryList.add(country)
                        })
                    })
                }
                state.metadata.countries = Array.from(countryList).sort().map((value) => { return { label: value, value } });
                state.metadata.regions = Array.from(regionList).sort().map((value) => { return { label: value, value } });
                state.metadata.site_types = Array.from(siteTypeList).sort().map((value) => { return { label: value, value } });
                state.metadata.process_paths = (responseAttribute.process_path || []).slice().sort((a, b) => a.value > b.value ? 1 : -1).map((pp) => {
                    return {
                        label: pp.value,
                        value: JSON.stringify(pp)
                    }
                });
                state.metadata.job_level = responseAttribute.job_levels.slice().sort((a, b) => +a > +b ? 1 : -1).map((value) => { return { label: value, value } });
                state.metadata.amazon_tenure = responseAttribute.amazon_tenure.map((tenure) => {
                    return {
                        label: tenure.value,
                        value: JSON.stringify(tenure)
                    }
                });
                state.metadata.employment_status = responseAttribute.employment_status.slice().sort((a, b) => a.value > b.value ? 1 : -1).map((status) => {
                    return {
                        label: status.value,
                        value: JSON.stringify(status)
                    }
                })

            })
            .addCase(audienceAttributesAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(jobTitlesAsync.pending, (state) => {
                state.typeaheadStatus = 'loading'
                state.metadata.job_titles = [];
            })
            .addCase(jobTitlesAsync.fulfilled, (state, action) => {
                state.typeaheadStatus = 'idle'
                state.metadata.job_titles = action.payload.records.sort((a: any, b: any) => a.name > b.name ? 1 : -1).map((record: any) => {
                    const profileInfo = {
                        profile_name: record.name,
                        profile_id: record.id,
                        profile_level: record.job_level
                    }

                    return {
                        label: profileInfo.profile_name,
                        value: JSON.stringify(profileInfo)
                    }
                })
            })
            .addCase(jobTitlesAsync.rejected, (state) => {
                state.typeaheadStatus = 'failed';
                console.log("Unable to load suggestions for jobTitles")
            })
            .addCase(employeeSuggestionsAsync.pending, (state) => {
                state.typeaheadStatus = 'loading';
                state.metadata.reports = [];
            })
            .addCase(employeeSuggestionsAsync.fulfilled, (state, action) => {
                state.typeaheadStatus = 'idle';
                state.metadata.reports = action.payload.sort().map((value: any) => {
                    return {
                        label: value,
                        value
                    }
                })
            })
            .addCase(employeeSuggestionsAsync.rejected, (state) => {
                state.typeaheadStatus = 'failed';
                console.log("Unable to load suggestions for employee aliases")
            })
    },
});

export const { setAudience, editAudience, deleteAudience, setAudienceVisible, setReportsType, addAudience, setAudienceList, resetCurrentAudience, resetAudience } = audienceSlice.actions;

export default audienceSlice.reducer;