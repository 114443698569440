import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import { clearAllNotification, removeNotification, selectNotification } from '../../../features/notification';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Badge from '@amzn/awsui-components-react/polaris/badge';
import './notifications.css';

export function Notifications() {
    const notifications = useAppSelector(selectNotification);
    const dispatch = useAppDispatch();
    const noteUpd = notifications.map((note, index) => {
        return ({ ...note, onDismiss: () => dispatch(removeNotification(index)) });
    }
    )
    return (
        <div>
            {
                noteUpd.length > 0 ?
                    (
                        <span className='clear-notif-badge' onClick={() => dispatch(clearAllNotification())}>
                            <Badge>Clear All</Badge>
                        </span>
                    ) :
                    (
                        <></>
                    )
            }
            <Flashbar items={noteUpd} stackItems={true} />
        </div>
    )
}