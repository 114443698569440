import { Box, Spinner } from "@amzn/awsui-components-react";
import './librarymetrics.css';
import { MetricFilterField, selectLibraryState } from "../../../../features/library";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { countTrainings } from "../../../../features/library/libraryAPI";

interface LibraryMetricsProp {
    filterInfo: MetricFilterField
}

function LibraryMetrics(props: LibraryMetricsProp) {
    const [metricValue, setMetricValue] = useState(-1);
    const [filterString, setFilterString] = useState("");
    const [metricStatus, setMetricStatus] = useState("idle");
    const libraryState = useAppSelector(selectLibraryState);

    useEffect(() => {
        const filterObject: any = {
            list_filters: {
                training_category: libraryState.selected_values.training_category.map((option) => option.value),
                ms_level: libraryState.selected_values.ms_level.map((option) => option.value),
                training_program_name: libraryState.selected_values.training_program.map((option) => option.value),
                training_type: libraryState.selected_values.training_type.map((option) => option.value),
                training_modality: libraryState.selected_values.training_modality.map((option) => option.value),
            },
            site_filters: {
                fetch_entire_hierarchy: false,
                orgs: libraryState.selected_audience.org.map((option) => option.value),
                suborgs: libraryState.selected_audience.suborgs.map((option) => option.value),
                countries: libraryState.selected_audience.countries.map((option) => option.value),
                site_types: libraryState.selected_audience.site_types.map((option) => option.value),
                sites:  libraryState.selected_audience.sites.map((option) => option.value)
                // TODO - Remove below 2 filters after confirmation
                // job_titles: libraryState.selected_audience.job_titles.map((option) => JSON.parse(option.value)),
                // job_levels: libraryState.selected_audience.job_level.map((option) => option.value),
            },
            boolean_filters: {},
            current_page: (libraryState.currentPage - 1),
            sort_dir: libraryState.descendingSorting ? "desc" : "asc",
            sort_field: libraryState.sortingColumn.sortingField
        }

        if (libraryState.selected_values.regulatory_requirement.length === 1) {
            filterObject['boolean_filters']['regulatory_requirement'] = libraryState.selected_values.regulatory_requirement.map((option) => option.value)[0];
        }

        //TODO: This is due to API bug we are unable to query by regulatory_requirement
        if (props.filterInfo.id !== "no-filter" && props.filterInfo.id !== "regulatory_requirement" && libraryState.filterLevel === "first") {
            filterObject.list_filters[props.filterInfo.id] = [props.filterInfo.value]
        } else if (props.filterInfo.id !== "no-filter" && props.filterInfo.id === "regulatory_requirement" && libraryState.filterLevel === "first") {
            const primaryFilterInfo = libraryState.primaryFilters[0];
            if (primaryFilterInfo.id !== "no-filter") {
                filterObject.list_filters[primaryFilterInfo.id] = [primaryFilterInfo.value]
            }
            if (!filterObject.boolean_filters['regulatory_requirement']) {
                filterObject.boolean_filters[props.filterInfo.id] = true;
            }
        } else if (props.filterInfo.id !== "no-filter" && props.filterInfo.id !== "regulatory_requirement" && libraryState.filterLevel === "second") {
            const primaryFilterInfo = libraryState.primaryFilters[0];
            if (primaryFilterInfo.id !== "no-filter") {
                filterObject.list_filters[primaryFilterInfo.id] = [primaryFilterInfo.value]
            }
            filterObject.list_filters[props.filterInfo.id] = [props.filterInfo.value];
        }

        const updatedFilterString = JSON.stringify(filterObject);
        if (filterString !== updatedFilterString && metricStatus === "idle") {
            setFilterString(updatedFilterString);

            setMetricStatus("loading");
            countTrainings(filterObject)
                .then(({ count }) => {
                    setMetricValue(count);
                    setMetricStatus("idle");
                })
                .catch((error) => {
                    console.log("Unable to load metrics", props.filterInfo.label, error);
                    setMetricStatus("idle")
                });
        }
    }, [filterString, libraryState, metricStatus, props.filterInfo])

    // const dispatch = useAppDispatch();

    // const handleFilterChange = useCallback((e: any) => {
    //     dispatch(setCountFilters(props.filterInfo));
    // }, [dispatch, props.filterInfo])

    return (
        <div className="highlight-info" >
            {/* onClick={handleFilterChange}> */}
            {
                metricStatus === "loading" ?
                    (
                        <Spinner />
                    ) :
                    (
                        <Box variant="awsui-value-large" fontSize="heading-xl">
                            {metricValue}
                        </Box>
                    )
            }
            <Box fontWeight="light" color="text-body-secondary">{props.filterInfo.label}</Box>
        </div>
    )
}

export default LibraryMetrics;