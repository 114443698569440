import { Box, Spinner } from "@amzn/awsui-components-react";
import './LoadSpinner.css';

function LoadSpinner() {
    return (
        <div className="spinner-container">
            <Spinner size="large"></Spinner><Box variant="h1" fontWeight="normal">Loading ...</Box>
        </div>
    )
}

export default LoadSpinner;