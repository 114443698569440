import { Tabs } from '@amzn/awsui-components-react';
import './audiencepopup.css';
import OperationalAudience from './operationalaudience';
import CorpAudience from './corpaudience';
import { audienceAttributesAsync, selectAudienceStatus, selectAudienceType } from '../../../../../../features/audience';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import LoadSpinner from '../../../../LoadSpinner';

export interface AudienceTabProps {
    audience_type: string;
}

function AudiencePopup() {

    const dispatch = useAppDispatch();
    const audienceType = useAppSelector(selectAudienceType);
    const audienceStatus = useAppSelector(selectAudienceStatus);

    return (
        <div>
            {
                audienceStatus === "loading" ?
                    (
                        <LoadSpinner />
                    ) :
                    (
                        <Tabs
                            activeTabId={audienceType}
                            onChange={(e: any) => dispatch(audienceAttributesAsync(e.detail.activeTabId))}
                            tabs={[
                                {
                                    label: "Operations",
                                    id: "Operations",
                                    content: <OperationalAudience />
                                },
                                {
                                    label: "Corporate",
                                    id: "WHS",
                                    content: <CorpAudience />
                                }
                            ]}
                        />
                    )
            }
        </div>
    )
}

export default AudiencePopup;