import './appbase.css';
import SideNavbar from "../sidenavbar";
import TopNavbar from "../topnavbar/topnavbar";
import { Outlet } from 'react-router-dom';
import { AppLayout } from '@amzn/awsui-components-react';
import { useRef } from 'react';
import { Notifications } from '../notifications';
import BreadCrumb from '../breadcrumb';

function AppBase() {

    const appLayout: any = useRef();

    return (
        <div className="base-layout-wrapper">
            <TopNavbar />
            <AppLayout
                ref={appLayout}
                navigation={<SideNavbar />}
                breadcrumbs={<BreadCrumb />}
                notifications={<Notifications />}
                toolsHide={true}
                content={
                    <Outlet />
                }
                headerSelector='#custom-nav-wrapper'
                contentType='wizard'
            />
        </div>
    )
}

export default AppBase;