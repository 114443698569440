import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import notificationReducer from '../features/notification';
import fetchTrainingSliceReducer from '../features/fetchTraining';
import trainingConfigurationSliceReducer from '../features/trainingConfiguration';
import trainingDetailsSliceReducer from '../features/trainingDetails';
import scheduleSliceReducer from '../features/schedule';
import audienceSliceReducer from '../features/audience';
import summarySliceReducer from '../features/summary';
import librarySliceReducer from '../features/library';
import authSliceReducer from '../features/auth';
import { loadState, saveState } from '../features/localStorage';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    notifications: notificationReducer,
    fetchTraining: fetchTrainingSliceReducer,
    trainingConfiguration: trainingConfigurationSliceReducer,
    trainingDetails: trainingDetailsSliceReducer,
    audience: audienceSliceReducer,
    schedule: scheduleSliceReducer,
    summary: summarySliceReducer,
    library: librarySliceReducer,
    auth: authSliceReducer,
  },
  preloadedState: loadState()
});

store.subscribe(() => {
  saveState(store.getState());
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;