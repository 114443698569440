import { useNavigate } from 'react-router-dom';
import './topnavbar.css';
import { useCallback } from 'react';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import { useAppSelector } from '../../hooks';
import { selectAuthUser } from '../../../features/auth';

function TopNavbar() {
    const navigate = useNavigate();
    const onFollow = useCallback(
        (e: any) => {
            e.preventDefault();
            navigate("/", { replace: true });
        },
        [navigate]
    );

    const username = useAppSelector(selectAuthUser);

    return (
        <div className="top-nav" id="custom-nav-wrapper">
            <TopNavigation
                identity={{
                    href: "/",
                    title: "Minerva",
                    logo: {
                        src:
                            "/favicon.ico",
                        alt: "Minerva"
                    },
                    onFollow
                }}
                utilities={[
                    {
                        type: "menu-dropdown",
                        text: username,
                        description: `${username}@amazon.com`,
                        iconName: "user-profile",
                        items: [
                            {
                                id: "support-group",
                                text: "Support",
                                items: [
                                    {
                                        id: "documentation",
                                        text: "Documentation",
                                        href: "https://w.amazon.com/bin/view/Whs/L&D/Minerva",
                                        external: true,
                                        externalIconAriaLabel:
                                            " (opens in new tab)"
                                    },
                                    {
                                        id: "river",
                                        text: "RIVER (Issues)",
                                        href: "https://river.amazon.com/workflow/BLR22/run/wfss-1b13f5d3-52c5-45af-8822-b71ed1c30997;jsessionid=E56EF8820018914A3D0747E83AF0DDF4?buildingType=WHS",
                                        external: true,
                                        externalIconAriaLabel:
                                            " (opens in new tab)"
                                    }
                                ]
                            }
                        ]
                    }
                ]}
                i18nStrings={{
                    searchIconAriaLabel: "Search",
                    searchDismissIconAriaLabel: "Close search",
                    overflowMenuTriggerText: "More",
                    overflowMenuTitleText: "All",
                    overflowMenuBackIconAriaLabel: "Back",
                    overflowMenuDismissIconAriaLabel: "Close menu"
                }}
            />
        </div>
    )
}

export default TopNavbar;