import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ScheduleFormOption {
    label: string;
    value: string;
};

export interface ScheduleInterval {
    value: string;
    type: ScheduleFormOption | null;
}

export interface ScheduleState {
    schedule_type: string;
    due_date: string;
    due_date_period: ScheduleInterval;
    frequency: ScheduleInterval;
}

let localDate = new Date();
localDate.setDate(localDate.getDate() + 8);

function formatDate(value: string) {
    const localDate = new Date(value);
    return localDate.getFullYear() + '-' + ('0' + (localDate.getMonth() + 1)).slice(-2) + '-' + ('0' + localDate.getDate()).slice(-2);
}

const initialState: ScheduleState = {
    schedule_type: "ONCE",
    due_date: formatDate(localDate.toDateString()),
    due_date_period: {
        value: "",
        type: null
    },
    frequency: {
        value: "",
        type: null
    }
}

export const selectScheduleType = (state: RootState) => state.schedule.schedule_type;
export const selectDueDate = (state: RootState) => state.schedule.due_date;
export const selectDueDateType = (state: RootState) => state.schedule.due_date_period.type;
export const selectDueDateValue = (state: RootState) => state.schedule.due_date_period.value;
export const selectRecurrenceType = (state: RootState) => state.schedule.frequency.type;
export const selectRecurrenceValue = (state: RootState) => state.schedule.frequency.value;
export const selectSchedule = (state: RootState) => state.schedule;

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        setScheduleType: (state, action: PayloadAction<string>) => {
            state.schedule_type = action.payload;
            if (action.payload === 'ONCE') {
                state.frequency.type = initialState.frequency.type;
                state.frequency.value = initialState.frequency.value;
                state.due_date_period.type = initialState.due_date_period.type;
                state.due_date_period.value = initialState.due_date_period.value;
                state.due_date = initialState.due_date;
            } else {
                state.due_date = "";
                state.frequency.value = "1";
                state.due_date_period.value = "1";
            }
        },
        setDueDate: (state, action: PayloadAction<string>) => {
            state.due_date = action.payload;
        },
        setDueDateType: (state, action: PayloadAction<ScheduleFormOption>) => {
            state.due_date_period.type = action.payload;
        },
        setDueDateValue: (state, action: PayloadAction<number>) => {
            state.due_date_period.value = Math.max(1, action.payload).toString();
        },
        setRecurrenceType: (state, action: PayloadAction<ScheduleFormOption>) => {
            state.frequency.type = action.payload;
        },
        setRecurenceValue: (state, action: PayloadAction<number>) => {
            state.frequency.value = Math.max(1, action.payload).toString();
        },
        resetSchedule: () => initialState
    }
});

export const { setScheduleType, setDueDate, setDueDateType, setDueDateValue, setRecurrenceType, setRecurenceValue, resetSchedule } = scheduleSlice.actions;
export default scheduleSlice.reducer;