import { FormField, Icon, Link, Popover, Select } from "@amzn/awsui-components-react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { TrainingDetailFormOption, selectCurrentTrainingDetailOption, selectTrainingDetailOptions, selectTrainingDetailStatus, setTrainingDetailOption } from "../../../../../../features/trainingDetails";
import { useEffect, useState } from "react";
import { selectValidationStatus } from "../../../../../../features/trainingConfiguration";

export interface TrainingDetailsFormProps {
    id: string;
    label: string;
    placeholder: string;
}

const regulatoryOptions: TrainingDetailFormOption[] = [
    {
        label: "Yes",
        value: "true"
    },
    {
        label: "No",
        value: "false"
    }
]

function TrainingDetailsFormField(props: TrainingDetailsFormProps) {

    const dispatch = useAppDispatch();

    const formValues = useAppSelector(state => selectTrainingDetailOptions(state, props.id));
    const loadingStatus = useAppSelector(selectTrainingDetailStatus);
    const [formOptions, setFormOptions] = useState<TrainingDetailFormOption[]>([]);
    useEffect(() => {
        if (props.id === 'regulatory_requirement') {
            setFormOptions(regulatoryOptions);
        } else if (formValues && formValues.length > 0) {
            setFormOptions(formValues.slice().sort().map(value => {
                return {
                    label: value,
                    value
                }
            }))
        }
    }, [formValues, props.id]);

    const selectedOption = useAppSelector(state => selectCurrentTrainingDetailOption(state, props.id));
    const validationStatus = useAppSelector(selectValidationStatus);

    return (
        <div>
            <FormField label={
                props.id === "ms_level" ?
                    <>
                        {props.label}
                        <Popover
                            dismissButton={false}
                            position="top"
                            triggerType="custom"
                            content={
                                <>
                                    Please refer this policy{" "}
                                    <Link
                                        href="https://policy.a2z.com/docs/32135/publication"
                                        external={true}
                                        variant="primary"
                                        fontSize="body-s"
                                    >
                                        Policy Document
                                    </Link>
                                </>
                            }
                        >
                            &nbsp;&nbsp;<Icon name="status-info" />
                        </Popover>
                    </> :
                    <>{props.label}</>
            }
                constraintText="* Required"
                errorText={selectedOption || !validationStatus ? "" : `You cannot proceed without selecting ${props.label}`}
            >
                <Select
                    placeholder={props.placeholder}
                    selectedOption={selectedOption}
                    onChange={(e: any) =>
                        dispatch(setTrainingDetailOption({ id: props.id, value: e.detail.selectedOption }))
                    }
                    options={formOptions}
                    filteringType="auto"
                    statusType={loadingStatus === "loading" ? "loading" : "finished"}
                    loadingText="Loading Options ..."
                />
            </FormField>
        </div>
    )
}

export default TrainingDetailsFormField;