const currentHost = window.location.hostname;

const getEnvironment = () => {
    if (currentHost === "beta.minerva.whs.amazon.dev")
        return "beta";
    else if (currentHost === "integ.minerva.whs.amazon.dev")
        return "integ";
    else if (currentHost === "minerva.whs.amazon.dev")
        return "prod";
    else
        return "alpha";
}

export { getEnvironment };