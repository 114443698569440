import { PayloadAction, createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { libraryTrainings } from "./libraryAPI";
import { RootState } from "../../app/store";
import { loadTrainingDetailsMetadata } from "../trainingDetails/trainingDetailsAPI";
import { TrainingDetailFormOption, TrainingMetadata } from "../trainingDetails";
import { AudienceAttributes, AudienceFormPayload, AudienceMetadata, SiteDetail } from "../audience";
import { fetchAudienceAttribute, fetchSuggestionsJobTitle } from "../audience/audienceAPI";

export interface LibraryRecord {
    created_by: string;
    created_on: string;
    ms_level: string;
    regulatory_requirement: boolean;
    schedule_type: string;
    training_category: string;
    training_id: string;
    training_modality: string;
    training_name: string;
    training_program_name: string;
    training_source: string;
    training_source_id: string;
    training_type: string;
    updated_on: string;
    version: number
}

export interface LibrarySortingColumn {
    id: string;
    header: string;
    maxWidth: string;
    sortingField: string;
}

export interface LibraryMetadataPayload {
    id: string;
    value: TrainingDetailFormOption[];
}

export interface LibraryMetadataSelected {
    training_category: TrainingDetailFormOption[];
    ms_level: TrainingDetailFormOption[];
    training_program: TrainingDetailFormOption[];
    training_type: TrainingDetailFormOption[];
    training_modality: TrainingDetailFormOption[];
    training_module: TrainingDetailFormOption[];
    regulatory_requirement: TrainingDetailFormOption[];
};

export interface MetricFilterField {
    id: string;
    value: string;
    label: string;
}

export interface LibraryState {
    recordList: LibraryRecord[] | null;
    currentPage: number;
    pageSize: number;
    maxPages: number;
    sortingColumn: LibrarySortingColumn;
    descendingSorting: boolean;
    status: 'idle' | 'loading' | 'failed';
    typeaheadStatus: 'idle' | 'loading' | 'failed';
    options_metadata: TrainingMetadata;
    audience_metadata: AudienceMetadata;
    audience_attributes: AudienceAttributes;
    selected_audience: AudienceMetadata;
    selected_values: LibraryMetadataSelected;
    filterLevel: 'first' | 'second';
    primaryFilters: MetricFilterField[];
    secondaryFilters: MetricFilterField[];
    searchText: string;
}

const initialState: LibraryState = {
    recordList: null,
    currentPage: 1,
    pageSize: 10,
    maxPages: 1,
    sortingColumn: {
        id: "training_name",
        header: "Training Name",
        maxWidth: "200px",
        sortingField: "training_name"
    },
    descendingSorting: false,
    status: 'idle',
    typeaheadStatus: 'idle',
    options_metadata: {
        training_category: [],
        ms_level: [],
        training_program: [],
        training_type: [],
        training_modality: [],
        training_module: []
    },
    audience_metadata: {
        org: [],
        suborgs: [],
        regions: [],
        countries: [],
        site_types: [],
        sites: [],
        process_paths: [],
        job_titles: [],
        job_level: [],
        amazon_tenure: [],
        employment_status: [],
        reports: [],
    },
    audience_attributes: {
        amazon_tenure: [],
        employment_status: [],
        job_levels: [],
        process_path: [],
        regions: [],
        sites: []
    },
    selected_audience: {
        org: [],
        suborgs: [],
        regions: [{ label: 'NA', value: 'NA' }],
        countries: [],
        site_types: [],
        sites: [],
        process_paths: [],
        job_titles: [],
        job_level: [],
        amazon_tenure: [],
        employment_status: [],
        reports: [],
    },
    selected_values: {
        training_category: [],
        ms_level: [],
        training_program: [],
        training_type: [],
        training_modality: [],
        training_module: [],
        regulatory_requirement: []
    },
    filterLevel: 'first',
    primaryFilters: [
        {
            id: 'no-filter',
            value: '',
            label: 'Training'
        },
        {
            id: 'regulatory_requirement',
            value: "true",
            label: 'Regulatory Training'
        }
    ],
    secondaryFilters: [
        {
            id: 'ms_level',
            value: 'Level 2',
            label: 'L2 standard'
        },
        {
            id: 'ms_level',
            value: 'Level 3',
            label: 'L3 Procedure'
        },
        {
            id: 'ms_level',
            value: 'Level 4',
            label: 'L4 site specific program'
        }
    ],
    searchText: '',
}

export const libraryRecordsAsync = createAsyncThunk(
    'library/libraryRecordsAsync',
    async (_, { getState }) => {
        const libraryState = (getState() as RootState).library;

        let filterObject: any = {
            list_filters: {
                training_category: libraryState.selected_values.training_category.map((option) => option.value),
                ms_level: libraryState.selected_values.ms_level.map((option) => option.value),
                training_program_name: libraryState.selected_values.training_program.map((option) => option.value),
                training_type: libraryState.selected_values.training_type.map((option) => option.value),
                training_modality: libraryState.selected_values.training_modality.map((option) => option.value),
            },
            site_filters: {
                fetch_entire_hierarchy: false,
                orgs: libraryState.selected_audience.org.map((option) => option.value),
                suborgs: libraryState.selected_audience.suborgs.map((option) => option.value),
                countries: libraryState.selected_audience.countries.map((option) => option.value),
                site_types: libraryState.selected_audience.site_types.map((option) => option.value),
                sites:  libraryState.selected_audience.sites.map((option) => option.value)
                // TODO - Remove below 2 filters after confirmation
                // job_titles: libraryState.selected_audience.job_titles.map((option) => JSON.parse(option.value)),
                // job_levels: libraryState.selected_audience.job_level.map((option) => option.value),
            },
            text_search_filters: {
                training_name: libraryState.searchText
            },
            page_size: libraryState.pageSize,
            current_page: (libraryState.currentPage - 1),
            sort_dir: libraryState.descendingSorting ? "desc" : "asc",
            sort_field: libraryState.sortingColumn.sortingField
        }

        if (libraryState.selected_values.regulatory_requirement.length === 1) {
            filterObject['boolean_filters'] = {
                regulatory_requirement: libraryState.selected_values.regulatory_requirement.map((option) => option.value)[0]
            };
        }

        const response = await libraryTrainings(filterObject);
        return response;
    }
);

export const loadLibraryTrainingMetadataAsync = createAsyncThunk(
    'library/loadLibraryTrainingMetadataAsync',
    async () => {
        const response = await loadTrainingDetailsMetadata();

        return response;
    }
);

export const loadAudienceMetatdataAsync = createAsyncThunk(
    'library/loadAudienceMetatdataAsync',
    async () => {
        const audience_type = 'Operations';
        const response = await fetchAudienceAttribute(audience_type);
        return {
            audience_type: audience_type,
            response: response
        }
    }
);

export const libraryJobTitlesAsync = createAsyncThunk(
    'library/libraryJobTitlesAsync',
    async (filterText: string) => {
        const response = await fetchSuggestionsJobTitle(filterText);
        return response;
    }
);

export const selectLibraryStatus = (state: RootState) => state.library.status;
export const selectLibraryTypeaheadStatus = (state: RootState) => state.library.typeaheadStatus;
export const selectLibraryRecords = (state: RootState) => state.library.recordList;
export const selectLibraryPage = (state: RootState) => state.library.currentPage;
export const selectLibraryMaxPages = (state: RootState) => state.library.maxPages;
export const selectSortingColumn = (state: RootState) => state.library.sortingColumn;
export const selectDescendingSorting = (state: RootState) => state.library.descendingSorting;
export const selectLibraryTrainingOptions = (state: RootState, id: string) => state.library.options_metadata[id as keyof TrainingMetadata];
export const selectLibraryAudienceOptions = (state: RootState, id: string) => state.library.audience_metadata[id as keyof AudienceMetadata];
export const selectLibraryCurrentAudience = (state: RootState, id: string) => state.library.selected_audience[id as keyof AudienceMetadata];
export const selectCurrentLibraryOption = (state: RootState, id: string) => {
    if (state.library.selected_values === undefined) {
        return []
    } else {
        return state.library.selected_values[id as keyof LibraryMetadataSelected]
    }
};
export const selectPrimaryFilters = (state: RootState) => state.library.primaryFilters;
export const selectSecondaryFilters = (state: RootState) => state.library.secondaryFilters;
export const selectLibraryState = (state: RootState) => state.library;
export const selectLibrarySearchText = (state: RootState) => state.library.searchText;
export const selectPageSize = (state: RootState) => state.library.pageSize;

export const librarySlice = createSlice({
    name: 'library',
    initialState,
    reducers: {
        resetLibrary: () => initialState,
        setLibrarySearchText: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
            //Note - We are disabling set pages to check feasibility of calculating maxpages separately
            // state.maxPages = action.payload;
        },
        setPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setSortingColumn: (state, action: PayloadAction<any>) => {
            state.sortingColumn = action.payload;
        },
        setDescendingSorting: (state, action: PayloadAction<boolean>) => {
            state.descendingSorting = action.payload;
        },
        setLibraryTrainingOption: (state, action: PayloadAction<LibraryMetadataPayload>) => {
            console.log(action.payload.value);
            state.selected_values[action.payload.id as keyof LibraryMetadataSelected] = action.payload.value;
        },
        setCountFilters: (state, action: PayloadAction<MetricFilterField>) => {
            if (state.filterLevel === "first") {
                state.filterLevel = "second";
                state.primaryFilters = [
                    action.payload
                ];
                state.secondaryFilters = state.options_metadata.training_type.map((value) => {
                    return {
                        id: "training_type",
                        value: value,
                        label: value
                    };
                });
            } else {
                state.filterLevel = "first";
                state.primaryFilters = initialState.primaryFilters;
                state.secondaryFilters = initialState.secondaryFilters;
            }
        },
        setLibraryAudience: (state, action: PayloadAction<AudienceFormPayload>) => {

            state.selected_audience[action.payload.id] = action.payload.value;
            const currentSelectedOptions = current(state.selected_audience);
            const siteFilters = currentSelectedOptions.sites.map((formInfo) => formInfo.value);
            const orgFilters = currentSelectedOptions.org.map((formInfo) => formInfo.value);
            const suborgFilters = currentSelectedOptions.suborgs.map((formInfo) => formInfo.value);
            const regionFilters = currentSelectedOptions.regions.map((formInfo) => formInfo.value);
            const countryFilters = currentSelectedOptions.countries.map((formInfo) => formInfo.value);
            const siteTypeFilters = currentSelectedOptions.site_types.map((formInfo) => formInfo.value);

            const audienceAttributes = current(state.audience_attributes);


            const siteList: string[] = [];
            const orgList = new Set<string>();
            const suborgList = new Set<string>();
            const regionList = new Set<string>();
            const countryList = new Set<string>();
            const siteTypeList = new Set<string>();


            audienceAttributes.sites.forEach((siteDetail: SiteDetail) => {

                // To filter any field we'll check all other fields except the current field
                const orgCondition = (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                    (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                    (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                    (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                const suborgCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                    (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                    (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                    (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                const siteTypeCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                    (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                    (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                    (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                const regionCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                    (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                    (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                    (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                    (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                const countryCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                    (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                    (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType)) &&
                    (siteFilters.length === 0 || siteFilters.includes(siteDetail.site));

                const siteCondition = (orgFilters.length === 0 || orgFilters.includes(siteDetail.org)) &&
                    (suborgFilters.length === 0 || suborgFilters.includes(siteDetail.suborg)) &&
                    (countryFilters.length === 0 || countryFilters.includes(siteDetail.country)) &&
                    (siteTypeFilters.length === 0 || siteTypeFilters.includes(siteDetail.siteType));

                if (orgCondition) {
                    orgList.add(siteDetail.org);
                }
                if (suborgCondition) {
                    suborgList.add(siteDetail.suborg);
                }
                if (siteTypeCondition) {
                    siteTypeList.add(siteDetail.siteType);
                }
                if (regionCondition) {
                    regionList.add(siteDetail.region);
                }
                if (countryCondition) {
                    countryList.add(siteDetail.country);
                }
                if (siteCondition) {
                    siteList.push(siteDetail.site);
                }
            });

            state.audience_metadata.sites = siteList.map((value) => { return { label: value, value } });
            state.audience_metadata.countries = Array.from(countryList).map((value) => { return { label: value, value } });
            state.audience_metadata.regions = Array.from(regionList).map((value) => { return { label: value, value } });
            state.audience_metadata.site_types = Array.from(siteTypeList).map((value) => { return { label: value, value } });
            state.audience_metadata.suborgs = Array.from(suborgList).map((value) => { return { label: value, value } });
            state.audience_metadata.org = Array.from(orgList).map((value) => { return { label: value, value } });
        },
        resetLibraryFilters: (state) => {
            state.selected_values = initialState.selected_values;
            state.selected_audience = initialState.selected_audience;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(libraryRecordsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(libraryRecordsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.recordList = action.payload.trainings;
                state.maxPages = Math.ceil(action.payload.maxPages / current(state).pageSize);
            })
            .addCase(libraryRecordsAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(loadLibraryTrainingMetadataAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadLibraryTrainingMetadataAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.options_metadata = action.payload;
            })
            .addCase(loadLibraryTrainingMetadataAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(loadAudienceMetatdataAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadAudienceMetatdataAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                const responseAttribute: AudienceAttributes = action.payload.response;
                state.audience_attributes = responseAttribute;

                const siteList: string[] = [];
                const orgList = new Set<string>();
                const suborgList = new Set<string>();
                const regionList = new Set<string>();
                const countryList = new Set<string>();
                const siteTypeList = new Set<string>();

                (responseAttribute.sites || []).forEach((siteDetail: SiteDetail) => {
                    siteList.push(siteDetail.site);
                    orgList.add(siteDetail.org);
                    suborgList.add(siteDetail.suborg);
                    regionList.add(siteDetail.region);
                    countryList.add(siteDetail.country);
                    siteTypeList.add(siteDetail.siteType);
                })

                state.audience_metadata.sites = siteList.sort().map((value) => { return { label: value, value } });
                state.audience_metadata.org = Array.from(orgList).sort().map((value) => { return { label: value, value } });
                state.audience_metadata.suborgs = Array.from(suborgList).sort().map((value) => { return { label: value, value } });

                if (action.payload.audience_type === "WHS") {
                    (action.payload.response.regions || []).forEach((region: any) => {
                        regionList.add(region.id);
                        region.countryCodes.forEach((country: string) => {
                            countryList.add(country)
                        })
                    })
                }
                state.audience_metadata.countries = Array.from(countryList).sort().map((value) => { return { label: value, value } });
                state.audience_metadata.regions = Array.from(regionList).sort().map((value) => { return { label: value, value } });
                state.audience_metadata.site_types = Array.from(siteTypeList).sort().map((value) => { return { label: value, value } });
                state.audience_metadata.process_paths = (responseAttribute.process_path || []).map((pp) => {
                    return {
                        label: pp.value,
                        value: JSON.stringify(pp)
                    }
                });
                state.audience_metadata.job_level = responseAttribute.job_levels.map((value) => { return { label: value, value } });
                state.audience_metadata.amazon_tenure = responseAttribute.amazon_tenure.map((tenure) => {
                    return {
                        label: tenure.value,
                        value: JSON.stringify(tenure)
                    }
                });
                state.audience_metadata.employment_status = responseAttribute.employment_status.map((status) => {
                    return {
                        label: status.value,
                        value: JSON.stringify(status)
                    }
                })
            })
            .addCase(loadAudienceMetatdataAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(libraryJobTitlesAsync.pending, (state) => {
                state.typeaheadStatus = 'loading';
            })
            .addCase(libraryJobTitlesAsync.fulfilled, (state, action) => {
                state.typeaheadStatus = 'idle';
                state.audience_metadata.job_titles = action.payload.records.map((record: any) => {
                    const profileInfo = {
                        profile_name: record.name,
                        profile_id: record.id,
                        profile_level: record.job_level
                    }

                    return {
                        label: profileInfo.profile_name,
                        value: JSON.stringify(profileInfo)
                    }
                })
            })
            .addCase(libraryJobTitlesAsync.rejected, (state) => {
                state.typeaheadStatus = 'failed';
                console.log("Unable to load suggestions for jobTitles")
            })
    },
});

export const { resetLibrary, setLibrarySearchText, setPage, setPageSize, setSortingColumn, setDescendingSorting, setLibraryTrainingOption, setCountFilters, setLibraryAudience, resetLibraryFilters } = librarySlice.actions;
export default librarySlice.reducer;