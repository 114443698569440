export const IS_LOGGED_IN = "yes";
export const AMPLIFY_AUTH_TYPE = "code";

export enum LOGIN_STATES {
    loggedIn = "yes",
    notLoggedIn = "no",
    loginFailed = "failed"
};

export enum REDUCER_STATES {
    IDLE = "idle",
    LOADING = "loading",
    FAILED = "failed"
}

export enum SIGNIN_STATES {
    success = "signIn",
    failure = "signIn_failure"
}

export const FEDERATE_CUSTOM_CLAIM = "custom:groups";

export const FEDERATE_INTEG_STAGES = ["alpha", "beta", "integ"];
export const FEDERATE_INTEG_POSIX_GROUP = "whs-minerva-admin-na";

export const FEDERATE_PROD_STAGES = ["prod"];
export const FEDERATE_PROD_POSIX_GROUP = "prod-whs-minerva-admin-na";

export const QUICKSIGHT_URL = "/trainingreport";