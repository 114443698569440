import {useEffect, useState} from "react";

import { Auth, Hub } from 'aws-amplify';
import { RouterProvider } from "react-router-dom";
import { router } from "../../routing/routing";
import { Modal, Spinner } from "@amzn/awsui-components-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    selectIsLoggedIn,
    selectRedirectURI,
    setAdmin,
    setAuthLoggedIn,
    setAuthStatus,
    setRedirectURI,
    setUser
} from "../../../features/auth";
import { STAGE_NAME } from "../../../features/apiConstants";
import { AMPLIFY_AUTH_TYPE, FEDERATE_CUSTOM_CLAIM, FEDERATE_INTEG_POSIX_GROUP, FEDERATE_INTEG_STAGES, FEDERATE_PROD_POSIX_GROUP, LOGIN_STATES, REDUCER_STATES, SIGNIN_STATES } from "../../../config/commonConstants";

export const CognitoAuthenticator = () => {

    const redirectURI = useAppSelector(selectRedirectURI);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const [modalVisible, setModalVisible] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        dispatch(setAuthStatus(REDUCER_STATES.LOADING));
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case SIGNIN_STATES.success:
                    dispatch(setAuthStatus(REDUCER_STATES.IDLE));
                    dispatch(setAuthLoggedIn(LOGIN_STATES.loggedIn));
                    window.location.href = redirectURI;
                    dispatch(setRedirectURI(""));
                    break;
                case SIGNIN_STATES.failure:
                    dispatch(setAuthStatus(REDUCER_STATES.FAILED));
                    dispatch(setAuthLoggedIn(LOGIN_STATES.loginFailed));
                    console.log("the user failed to sign in");
                    console.log("the error is", data);
                    break;
                default:
                    break;
            }
        });

        Auth.currentAuthenticatedUser()
            .then((data) => {
                const userPayload = data.signInUserSession.idToken.payload;
                dispatch(setUser(userPayload.preferred_username))
                if (FEDERATE_INTEG_STAGES.includes(STAGE_NAME)) {
                    dispatch(setAdmin((userPayload[FEDERATE_CUSTOM_CLAIM] || '').includes(FEDERATE_INTEG_POSIX_GROUP)));
                } else {
                    dispatch(setAdmin((userPayload[FEDERATE_CUSTOM_CLAIM] || '').includes(FEDERATE_PROD_POSIX_GROUP)));
                }
                dispatch(setAuthLoggedIn(LOGIN_STATES.loggedIn));
                dispatch(setAuthStatus(REDUCER_STATES.IDLE));
            })
            .catch((err) => {
                dispatch(setAuthLoggedIn(LOGIN_STATES.notLoggedIn));
                //look for code in URL, if code is there, it means user is already authenticated
                if (!params.has(AMPLIFY_AUTH_TYPE)) {
                    if (window.location.href) {
                        dispatch(setRedirectURI(window.location.href));
                    }
                    Auth.federatedSignIn({ customProvider: "AmazonFederate" })
                        .then(data => {})
                        .catch(error => {
                            console.log(error);
                            dispatch(setAuthLoggedIn(LOGIN_STATES.loginFailed));
                            setModalVisible(true)
                        });
                }
            });
    }, [redirectURI, dispatch])

    return (
        isLoggedIn === LOGIN_STATES.loggedIn
            ? (
                <RouterProvider router={router} />
            )
            : (isLoggedIn === LOGIN_STATES.notLoggedIn
                ? <Spinner />
                : <Modal
                    onDismiss={(event) => setModalVisible(false)}
                    visible={modalVisible}
                    closeAriaLabel="Close modal"
                    header="Unable to load Minerva Site"
                >
                    We were unable to authenticate your alias. Please check if you have the necessary permission to access this site.
                </Modal>
            )
    )
}