import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { Auth } from "aws-amplify";

export async function loadTrainingDetailsMetadata(trainingId: string = "dummy value") {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.get(`${BASE_URL}/config/v1/metadata`, requestHeaders);
    return data;
}