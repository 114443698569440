import { Container, Header, Box, ColumnLayout } from "@amzn/awsui-components-react";
import { useAppSelector } from "../../../../hooks";
import { selectLMSTraining } from "../../../../../features/trainingConfiguration";
import './verifytraining.css';
import { formatDate, parseDuration } from "../../../../../features/fetchTraining/fetchTrainingHelper";

function VerifyTrainingForm() {

    const selectedLMSTraining = useAppSelector(selectLMSTraining);

    const defaultMessage = (lms: string) => {
        return (
            <i>Unavailable from {lms}</i>
        );
    }

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description={
                        <span dangerouslySetInnerHTML={{ __html: selectedLMSTraining.description || 'Training Description Unavailable' }}></span>
                    }
                >
                    {selectedLMSTraining.title}
                </Header>
            }
            className="configuration-container"
        >
            <hr className="content-separator" />
            <Box variant="p">LMS  : {selectedLMSTraining.lms || defaultMessage(selectedLMSTraining.lms)}</Box>
            <Box variant="p">Version  : {selectedLMSTraining.version || defaultMessage(selectedLMSTraining.lms)}</Box>
            <Box variant="p">Training Type  : {selectedLMSTraining.training_type || defaultMessage(selectedLMSTraining.lms)}</Box>
            <Box variant="p">Provider  : {selectedLMSTraining.provider_name || defaultMessage(selectedLMSTraining.lms)}</Box>
            <Box variant="p">Duration : {parseDuration(selectedLMSTraining.duration_in_iso) || defaultMessage(selectedLMSTraining.lms)}</Box>
            <Box variant="p">Updated On  : {formatDate(selectedLMSTraining.updated_date) || defaultMessage(selectedLMSTraining.lms)}</Box>
            <Box variant="p">Updated By  : {selectedLMSTraining.updated_by || defaultMessage(selectedLMSTraining.lms)}</Box>
            <hr className="content-separator" />
            <Box variant="awsui-key-label" fontSize="heading-s">Available Language(s)</Box>
            <ColumnLayout columns={3}>
                {
                    selectedLMSTraining.languages_available.map((language, index) => (
                        <Box color="text-body-secondary" key={index}>{language}</Box>
                    ))
                }
            </ColumnLayout>
        </Container>
    )
}

export default VerifyTrainingForm;