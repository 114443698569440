import { getEnvironment } from "./environment"

const callbackUrl = window.location.origin

const getCognitoConfig = (stage: string) => {

    switch (stage) {
        case 'alpha': {
            return {
                aws_project_region: "us-west-2",
                aws_cognito_region: "us-west-2",
                aws_user_pools_id: "us-west-2_p31KpjEgm",
                aws_user_pools_web_client_id: "3dss3haeseftdt6poosjfjshbc",
                oauth: {
                    domain: "auth.alpha.minerva.whs.amazon.dev",
                    scope: ["openid"],
                    redirectSignIn: callbackUrl,
                    redirectSignOut: callbackUrl,
                    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
                }
            };
        }
        case 'beta': {
            return {
                aws_project_region: "us-west-2",
                aws_cognito_region: "us-west-2",
                aws_user_pools_id: "us-west-2_LBSWQhDt0",
                aws_user_pools_web_client_id: "38glukdp69shae1t917sb6jh1t",
                oauth: {
                    domain: "auth.beta.minerva.whs.amazon.dev",
                    scope: ["openid"],
                    redirectSignIn: callbackUrl,
                    redirectSignOut: callbackUrl,
                    responseType: "code",
                }
            };
        }
        case 'integ': {
            return {
                aws_project_region: "us-west-2",
                aws_cognito_region: "us-west-2",
                aws_user_pools_id: "us-west-2_lZzudB9KO",
                aws_user_pools_web_client_id: "4kuglaumcp5ck6hfjba5erujeh",
                oauth: {
                    domain: "auth.integ.minerva.whs.amazon.dev",
                    scope: ["openid"],
                    redirectSignIn: callbackUrl,
                    redirectSignOut: callbackUrl,
                    responseType: "code",
                }
            };
        }
        case 'prod': {
            return {
                aws_project_region: "us-west-2",
                aws_cognito_region: "us-west-2",
                aws_user_pools_id: "us-west-2_5OvHKPPoO",
                aws_user_pools_web_client_id: "3vr2oog4gb32inocgaeqhgfp3l",
                oauth: {
                    domain: "auth.minerva.whs.amazon.dev",
                    scope: ["openid"],
                    redirectSignIn: callbackUrl,
                    redirectSignOut: callbackUrl,
                    responseType: "code",
                }
            };
        }
    }
}

export default getCognitoConfig(getEnvironment());