import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { crumbMap } from "../../routing/routing";

function BreadCrumb() {

    const location = useLocation();
    const navigate = useNavigate();

    const pathItems = Array.from(new Set(location.pathname.split("/")))
    if (pathItems[2] === 'trainingsummary') {
        pathItems.pop();
    }
    const crumbItems = pathItems.map((currentpath: string) => crumbMap[currentpath] ?? crumbMap['/']);

    const crumbNavigate = useCallback(
        (event: any) => {
            event.preventDefault();
            navigate(event.detail.href, { replace: true });
        },
        [navigate]
    );

    return (
        <div>
            <BreadcrumbGroup
                items={crumbItems}
                ariaLabel="Breadcrumbs"
                onClick={crumbNavigate}
            />
        </div>
    )
}

export default BreadCrumb;