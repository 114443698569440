import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { TrainingDetail } from "../fetchTraining";
import { fetchTrainingDetails, saveTrainingConfiguration, updateTrainingConfiguration } from "./trainingConfigurationAPI";
import { ScheduleState } from "../schedule";
import { AudienceDetail } from "../audience";
import { TrainingMetadataSelected } from "../trainingDetails";

export interface LMSTrainingDetail {
    id: string;
    lms: string;
    learn_training_id: string;
    training_type: string;
    provider_name: string;
    platform_training_id: string;
    version: string;
    duration_in_iso: string;
    title: string;
    description: string;
    deep_link: string[];
    languages_available: string[];
    updated_by: string;
    updated_date: string;
}

export interface TrainingConfigurationState {
    selectedLMSTraining: LMSTrainingDetail;
    status: 'idle' | 'loading' | 'failed';
    saveStatus: 'idle' | 'loading' | 'failed' | 'success';
    saveId?: string;
    version?: number;
    validationStatus: boolean;
}

export interface SavePayload {
    lmsTraining: LMSTrainingDetail,
    schedule: ScheduleState,
    audience: AudienceDetail[],
    trainingDetails: TrainingMetadataSelected,
    username: string;
}

//TO-DO : Change the default values to be relevant to API
const initialLMSTraining: LMSTrainingDetail = {
    id: "default-init-code",
    lms: "Default",
    learn_training_id: "default-init-code",
    training_type: "PLACEHOLDER",
    provider_name: "DEFAULT PROVIDER",
    platform_training_id: "default-init-code",
    version: "1.0",
    duration_in_iso: "PT0H30M0S",
    title: "No Training Selected",
    description: "Please select a training first",
    deep_link: [],
    languages_available: [],
    updated_by: "johndoe",
    updated_date: "01/01/2023"
}

const initialState: TrainingConfigurationState = {
    selectedLMSTraining: initialLMSTraining,
    status: 'idle',
    saveStatus: 'idle',
    validationStatus: false
}

export const selectLMSTraining = (state: RootState) => state.trainingConfiguration.selectedLMSTraining;
export const selectConfigurationStatus = (state: RootState) => state.trainingConfiguration.status;
export const selectConfigurationSaveStatus = (state: RootState) => state.trainingConfiguration.saveStatus;
export const selectSaveId = (state: RootState) => state.trainingConfiguration.saveId;
export const selectValidationStatus = (state: RootState) => state.trainingConfiguration.validationStatus;

export const fetchTrainingDetailsAsync = createAsyncThunk(
    'trainingConfiguration/fetchTrainingDetailsAsync',
    async (training: TrainingDetail) => {
        const response = await fetchTrainingDetails(training.id);

        return response;
    }
);

export function timeMultiplier(unit: string = 'day') {
    switch (unit) {
        case 'day':
            return 1;
        case 'week':
            return 7;
        case 'month':
            return 30;
        case 'quarter':
            return 90;
        case 'year':
            return 365;

        default:
            return 1;
    }
}

export const saveTrainingDetailsAsync = createAsyncThunk(
    'trainingConfiguration/saveTrainingDetailsAsync',
    async (savePayload: SavePayload, { getState }) => {
        const trainingConfigState = (getState() as RootState).trainingConfiguration;
        const trainingId = trainingConfigState.saveId;
        const version = trainingConfigState.version;
        const saveObject: any = {
            training_name: savePayload.lmsTraining.title,
            training_source_id: savePayload.lmsTraining.id,
            training_id: trainingId,
            training_description: savePayload.lmsTraining.description,
            ms_level: savePayload.trainingDetails.ms_level?.value,
            training_source: savePayload.lmsTraining.lms.toUpperCase(),
            mandatory_status: true, //TODO - Check source for value of this field
            training_category: savePayload.trainingDetails.training_category?.value,
            training_type: savePayload.trainingDetails.training_type?.value,
            training_modality: savePayload.trainingDetails.training_modality?.value,
            regulatory_requirement: savePayload.trainingDetails.regulatory_requirement?.value,
            training_program_name: savePayload.trainingDetails.training_program?.value,
            schedule_type: savePayload.schedule.schedule_type,
            audience_list: savePayload.audience,
            status: "COMPLETE",
            created_by: savePayload.username,
            version
        }

        if (savePayload.schedule.schedule_type === "RECURRENCE") {
            saveObject['frequency'] = {
                type: savePayload.schedule.frequency.type?.value,
                value: savePayload.schedule.frequency.value,
                days: parseInt(savePayload.schedule.frequency.value) * timeMultiplier(savePayload.schedule.frequency.type?.value)
            };
            saveObject['due_date_period'] = {
                type: savePayload.schedule.due_date_period.type?.value,
                value: savePayload.schedule.due_date_period.value,
                days: parseInt(savePayload.schedule.due_date_period.value) * timeMultiplier(savePayload.schedule.due_date_period.type?.value)
            };
        } else {
            if (trainingId) {
                saveObject['next_due_date'] = savePayload.schedule.due_date + 'T00:00:00Z';
            } else {
                saveObject['due_date'] = savePayload.schedule.due_date + 'T00:00:00Z';
            }
        }

        //TODO - Remove this log but after UAT testing
        console.log("recheck save object log", saveObject);
        if (trainingId) {
            return await updateTrainingConfiguration(saveObject);
        } else {
            return await saveTrainingConfiguration(saveObject);
        }
    }
);

export const trainingConfigurationSlice = createSlice({
    name: 'trainingConfiguration',
    initialState,
    reducers: {
        cancelLMSTraining: () => initialState,
        setValidationStatus: (state, action: PayloadAction<boolean>) => {
            state.validationStatus = action.payload;
        },
        setLMSTraining: (state, action: PayloadAction<LMSTrainingDetail>) => {
            state.selectedLMSTraining = action.payload;
        },
        setSaveId: (state, action: PayloadAction<string>) => {
            state.saveId = action.payload;
        },
        setVersion: (state, action: PayloadAction<number>) => {
            state.version = action.payload;
        },
        setSaveStatus: (state, action: PayloadAction<any>) => {
            state.saveStatus = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTrainingDetailsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTrainingDetailsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.selectedLMSTraining = action.payload;
            })
            .addCase(fetchTrainingDetailsAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(saveTrainingDetailsAsync.pending, (state) => {
                state.saveStatus = 'loading';
            })
            .addCase(saveTrainingDetailsAsync.fulfilled, (state, action) => {
                state.saveStatus = 'success';
                state.saveId = action.payload.training_id;
            })
            .addCase(saveTrainingDetailsAsync.rejected, (state) => {
                state.saveStatus = 'failed';
                console.log("save failed");
            })
    },
});


export const { cancelLMSTraining, setValidationStatus, setLMSTraining, setSaveId, setVersion, setSaveStatus } = trainingConfigurationSlice.actions;

export default trainingConfigurationSlice.reducer;