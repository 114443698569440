import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { selectAuthAdmin } from "../../../features/auth";
import { selectLMSTraining } from "../../../features/trainingConfiguration";
import { TrainingMetadataSelected, selectedTrainingDetail } from "../../../features/trainingDetails";
import { selectSchedule } from "../../../features/schedule";

export type ProtectedRouteProps = {
  outlet: JSX.Element;
};

function AuthGuard({ outlet }: ProtectedRouteProps) {

  const adminCheck = useAppSelector(selectAuthAdmin);
  const lmsTraining = useAppSelector(selectLMSTraining);
  const trainingDetails: TrainingMetadataSelected = useAppSelector(selectedTrainingDetail);
  const schedule = useAppSelector(selectSchedule);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathKey = location.pathname.split("/").pop();
    let conditionCheck = false;
    switch (pathKey) {
      case 'verifytraining':
        if (lmsTraining.id === 'default-init-code') {
          navigate('/trainingconfiguration/fetchtraining');
        }
        break;

      case 'trainingdetails':
        if (lmsTraining.id === 'default-init-code') {
          navigate('/trainingconfiguration/fetchtraining');
        }
        break;

      case 'trainingaudience':
        conditionCheck = (Object.keys(trainingDetails).filter((trainingKey) => {
          return trainingDetails[trainingKey as keyof TrainingMetadataSelected] !== null
        }).length < 6);
        console.log(conditionCheck);
        if (conditionCheck) {
          navigate('/trainingconfiguration/trainingdetails');
        }
        break;

      case 'trainingschedule':
        conditionCheck = (Object.keys(trainingDetails).filter((trainingKey) => {
          return trainingDetails[trainingKey as keyof TrainingMetadataSelected] !== null
        }).length < 6);
        if (conditionCheck) {
          navigate('/trainingconfiguration/trainingdetails');
        }
        break;

      default:
        break;
    }

    if (!adminCheck) {
      navigate("/")
    }

  }, [adminCheck, location, lmsTraining.id, trainingDetails, schedule, navigate]);

  return (
    adminCheck ?
      (
        outlet
      ) :
      (
        <div>
          <SpaceBetween size="xl">
            <Box variant="h1" fontWeight="light">Checking user permissions ...</Box>
            <Box variant="h1" fontWeight="light">You will be redirected automatically if you do not have access</Box>
          </SpaceBetween>
        </div>
      )
  )

}

export default AuthGuard;