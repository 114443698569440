import { SideNavigation, SideNavigationProps } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { QUICKSIGHT_URL } from "../../../config/commonConstants";
import { useAppSelector } from "../../hooks";
import { selectAuthAdmin } from "../../../features/auth";

function SideNavbar() {
    const [activeHref, setActiveHref] = useState("");
    const adminCheck = useAppSelector(selectAuthAdmin);

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        setActiveHref(`/${location.pathname.split("/")[1]}`);
    }, [location, setActiveHref]);

    const adminItems: SideNavigationProps.Item[] = [
        { type: "link", text: "Training Reports", href: QUICKSIGHT_URL, external: false },
        { type: "link", text: "Training Library", href: "/traininglibrary" },
        { type: "link", text: "Map Training", href: "/trainingconfiguration" }
    ];
    const nonAdminItems: SideNavigationProps.Item[] = [
        { type: "link", text: "Training Reports", href: QUICKSIGHT_URL, external: false },
        { type: "link", text: "Training Library", href: "/traininglibrary" }
    ]

    return (
        <div className="side-nav-wrapper">
            <SideNavigation
                activeHref={activeHref}
                header={{ href: "", text: "Minerva" }}
                onFollow={event => {
                    if (!event.detail.external) {
                        event.preventDefault();
                        if (event.detail.external) {
                            window.open(event.detail.href, '_blank')
                        } else {
                            navigate(event.detail.href);
                        }
                    }
                }}
                items={adminCheck ? adminItems : nonAdminItems}
            />
        </div>
    )
}

export default SideNavbar;