function CancelPopup() {
    return (
        <div>
            <span>Confirm you want to cancel?</span>
            <div>
                You will lose your current progress of Training Configuration
            </div>
        </div>
    )
}

export default CancelPopup;