import { Container, Header, ColumnLayout } from "@amzn/awsui-components-react";
import TrainingDetailsFormField, { TrainingDetailsFormProps } from "./trainingdetailsform";
import { useAppDispatch } from "../../../../hooks";
import { loadTrainingDetailsMetadataAsync } from "../../../../../features/trainingDetails";

const trainingDetailsDescription = "Additional Training details required for Training Configuration must be filled in this step";

export const trainingDetailsFormFields: TrainingDetailsFormProps[] = [
    {
        id: 'training_category',
        label: 'Training Category',
        placeholder: 'Select Training Category'
    },
    {
        id: 'ms_level',
        label: 'WHS Management System',
        placeholder: 'Select WHS Management System'
    },
    {
        id: 'training_program',
        label: 'Training Program',
        placeholder: 'Select Training Program'
    },
    {
        id: 'training_type',
        label: 'Training Type',
        placeholder: 'Select Training Type'
    },
    {
        id: 'training_modality',
        label: 'Training Mode',
        placeholder: 'Select Training Mode'
    },
    {
        id: 'regulatory_requirement',
        label: 'Regulatory Status',
        placeholder: 'Select Regulatory Status'
    }
]

function TrainingDetailsForm() {

    const dispatch = useAppDispatch();
    dispatch(loadTrainingDetailsMetadataAsync());

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description={trainingDetailsDescription}
                >
                    Add Training Details
                </Header>
            }
            className="configuration-container"
        >
            <ColumnLayout columns={2}>
                {
                    trainingDetailsFormFields.map((formInfo, index) =>
                    (
                        <TrainingDetailsFormField id={formInfo.id} label={formInfo.label} placeholder={formInfo.placeholder} key={index} />
                    ))
                }
            </ColumnLayout>
        </Container>
    )
}

export default TrainingDetailsForm;