import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { Auth } from "aws-amplify";

export async function fetchTraining(filterText: string = "dummy value") {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const { data } = await axios.get(`${BASE_URL}/lms/v1/search?search_text=${filterText}&lms=KNET`, requestHeaders)
    return data;
}

export async function validateTraining(trainingId: string) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };

    const requestBody = {
        list_filters: {
            training_source_id: [trainingId]
        },
        boolean_filters: {},
        site_filters: {
            "fetch_entire_hierarchy": false
        },
        text_search_filters: {},
        sort_field: "training_name",
        sort_dir: "asc",
        page_size: 10,
        current_page: 0,
    };
    const { data } = await axios.post(`${BASE_URL}/config/v1/trainings`, requestBody, requestHeaders);
    if (data.trainings.length === 0) {
        return {
            duplicate: false
        }
    } else {
        return {
            duplicate: true
        }
    }

}