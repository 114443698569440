import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { Auth } from "aws-amplify";

export async function countTrainings(filters: any) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const requestBody = {
        list_filters: {},
        site_filters: {
            "fetch_entire_hierarchy": false
        },
        boolean_filters: {},
        text_search_filters: {},
        sort_field: "training_name",
        sort_dir: "asc",
        page_size: 10,
        current_page: 0,
        ...filters
    };
    const { data } = await axios.post(`${BASE_URL}/config/v1/count_trainings`, requestBody, requestHeaders);
    return data;
}

export async function libraryTrainings(filters: any) {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    const requestHeaders = {
        headers: {
            Authorization: jwtToken
        }
    };
    const requestBody = {
        list_filters: {},
        site_filters: {
            "fetch_entire_hierarchy": false
        },
        boolean_filters: {},
        text_search_filters: {},
        sort_field: "training_name",
        sort_dir: "asc",
        page_size: 10,
        current_page: 0,
        ...filters
    };
    const recordsResponse = await axios.post(`${BASE_URL}/config/v1/trainings`, requestBody, requestHeaders);
    const countResponse = await axios.post(`${BASE_URL}/config/v1/count_trainings`, requestBody, requestHeaders);
    return {
        trainings: recordsResponse.data.trainings,
        maxPages: countResponse.data.count
    };
}